import * as React from "react";
import useStyles from './styles';
import Image from '../../assets/images/common-images/popUp.png';
import MyButton from "../Common/MyButton";

const Popup = (props) => {
  const classes = useStyles();
  return (
    <div className={`bg_modal_background ${props.show && 'modal_bg_show'}`} onClick={props.onCancelClick}>
      <div className={`bg_modal ${props.show && 'modal_show'}`}>
        <img src={Image} className={classes.tutorialImage} />
        <p style={{position: 'absolute', fontSize: 20, width: 200, textAlign: 'center'}}>{props.title}</p>
        <div style={{display: 'flex', flexDirection: 'row', position: 'absolute', marginTop: 160, transform: 'scale(80%)'}}>
          <div onClick={props.onYesClick}>
            <MyButton title={props.yesText}/>
          </div>
          <div onClick={props.onNoClick}>
            <MyButton title={props.noText}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;