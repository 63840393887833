import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tipsContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    margin: '0 auto',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
}));