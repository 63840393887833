import React, { useState } from 'react';
import useStyles from './styles';
import { sideFooterMenus } from '../../utils/sideFooterMenu';
import useWindowDimensions from '../../utils/windowDimention';
import { isMobile } from 'react-device-detect';

const SideFooter = (props) => {
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState(props.index);
  const { height, width } = useWindowDimensions();

  const styles = {
    mainDiv2: {
      position: 'absolute',
      width: '100%',
      paddingLeft: '5%',
      paddingRight: '5%',
      top: height - (isMobile ? 135 : 180),
      paddingTop: 0,
      paddingBottom: 30,
      display: 'flex',
      justifyContent: 'space-between',
      zIndex: 1,
      // background:  "linear-gradient(184deg, rgba(20,20,20,0.1) 0%, rgba(20,20,20,0.3) 0%, rgba(20,20,20,0.7) 100%)"
      backgroundColor: 'transparent'
    },
  };

  const menu = sideFooterMenus;

  return (
    <div style={styles.mainDiv2}>
      {menu.map((item, index) => (
        <div
          key={`item${index}`}
          className={classes.footerMenuContainer}
          onClick={() => {
            setSelectedMenu(index)
            props.setIndex(index)
          }}
        >
          <img
            className={classes.sideMenuIcon}
            src={item.icon}
            alt={item.label}
            // style={{width: index === 1 ? 44: 30, alignItems: 'flex-end'}}
          />
          {/* <label className={classes.menuLabel} style={{fontSize: index === 1 ? 12: 10}}>{item.label}</label> */}
          {/* <label className={classes.menuLabel} style={{color: 'orange'}}>{item.label}</label> */}
        </div>
      ))}
    </div>
  );
};

export default SideFooter;
