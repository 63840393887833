export const routes = {
  homepage: "/",
  login: "/login",
  home: "/home",
  maiar: "/maiar",
  word: '/word',
  show: '/show',
  history: '/history',
  collection: '/collection',
  collectionDetails: '/collectionDetails',
  profile: '/profile',
  notification: '/notification',
  famers: '/famers',
  topic: '/topic',
  number: '/number',
  leaders: '/leaders',
  topic2: '/topic2',
  lesson: '/lesson',
  lessonDetails: '/lessonDetails',
  steal: '/steal',
  henyo: '/henyo',
  henyo2: '/henyo2',
  henyo3: '/henyo3',
  slot: '/slot',
  lotto: '/lotto',
  funtyperunner: "/funtyperunner/:id",
  winners: '/winners',
  sumagot: '/result',
  tumawag: '/phone',
  magchat: '/groupChat',
  uminom: '/dosage',
  reseta: '/send',
  // bumili: '/savings',
  tanggap: '/distribute',
  tlSumagot: '/tl/result',
  tlUminom: '/tl/dosage',
  tlTanggap: '/tl/distribute',
  tlDelivery: '/tl/delivery',
  tlTranscribe: '/tl/transcribe',
  store: '/store',
  storeDetails: '/storeDetails',
  chart: '/chart',
  makatipid: '/medicines',
  boost: '/boost',
  boostDetails: '/boost/details',
  broadcast: '/broadcast',
  delivery: '/delivery',
  deliveryDetails: '/delivery/details',
  buy: '/buy',
  reorder: '/reorder',
  care: '/care',
  savings: '/savings',
  cart: '/cart',
};

export const ids = {
  MaxWinsTopic: 10,
  MaxWinsWord: 5,
  MaxWinsNumber: 5,

  FunTypeFamilyFamer: 1,
  FunTypeFamilyTourney: 2,
  FunTypeFamilyHighlight: 3,
  FunTypeFamilyRondleWords: 7,
  FunTypeFamilyRondleTopics: 8,
  FunTypeFamilyRondleNumbers: 9,

  //transactionType
  txnTypeJackpot: 3,
  txnTypeBattleFee: 5,
  txnTypeBattleWin: 6,
  txnTypeBattleDraw: 7,
  txnTypeBattleCancel: 8,
  txnTypeTourneyFee: 9,
  txtTypeWelcomeTreat: 17,
  txnTypeBoughtCharm: 20,
  txnTypeBrandFamer: 21,
  txnTypeBrandHOF: 22,
  txnTypeBrandJackpot: 23,
  txnTypeBrandNineBoxes: 24,
  txnTypeRondleWordWin: 25,
  txnTypeRondleTopicWin: 26,
  txnTypeRondleNumberWin: 27,
  txnTypeRondleWordFee: 28,
  txnTypeRondleTopicFee: 29,
  txnTypeRondleNumberFee: 30,

  notificationTypeBoughtFromStore: 38,
  notificationTypeConfetti: 40,
  notificationTypeSteal: 41,
  notificationTypeTLDeliver: 50,

  benggaStoreId: 1,

  gameTypeFunType: 0,
  gameTypeWordle: 1,
  gameTypeFillInTheBlanks: 2,
  gameType2048: 3,
  gameTypeMultipleChoice: 4,
  gameTypeWheel: 5,
  gameTypeSlotMachine: 6,
  gameTypeLotto: 7,
  gameTypeCubeRun: 8,
  gameTypePinoyHenyo: 9, //this that multiple choice
  gameTypePinoyHenyo2: 10, //this that know with keyboard
  gameTypePinoyHenyo3: 11, //clue then keyboard

  classA: 4,
  classB: 3,
  classC: 2,
  classD: 1,

  rondleTypeEarlyRelease: 0,
  rondleTypePharma: 1,
  rondleTypeDobleKahol: 2,

  //channel
  channelTourney: 0,
  channelFamers: 1,
  // channelCharm: 2,
  channelBrands: 2,
  channelJackpot: 3,
  channelBattle: 4,

  activityTypeTanggap: 1,
  activityTypeUminom: 2,
  activityTypeSumagot: 3,

  storeCategoryGC: 2,
}
