import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Uminom_1.png';
import checkButton from '../../../assets/images/common-images/checkIcon.png';
import innerYes from '../../../assets/images/common-images/oo_inom.png';
import Done from '../../../assets/images/common-images/done.png'

import {
  getBought, uploadTaken
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import MyButton from '../../../Components/Common/MyButton';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Uminom = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [sku, setSku] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [timeId, setTimeId] = useState(1);
  const props = useLocation().state;
  const [tabArr, setTabArr] = useState([
    { tabId: 1, tabName: 'Breakfast', isSelect: true },
    { tabId: 2, tabName: 'Lunch', isSelect: false },
    { tabId: 3, tabName: 'Dinner', isSelect: false },
  ])

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getBought();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setSku(response.data.data);
        setData(response.data.data.filter(item => (timeId == 1 && item.umaga > 0) || 
            (timeId == 2 && item.tanghali > 0) ||
            (timeId == 3 && item.gabi > 0)
          ).map((item) => {
          return {
            ...item,
            line1: {
              text: `${item.brand.name}\n(${item.generic.name} ${item.dosage.name})`,
              color: '#00599d',
              fontSize: 18
            },
            line2: {
              // text: this._getWhen(),
              text: item.remarks,
              color: '#000000',
              fontSize: 16
            },
            line3: {
              text: item.dosage.name,
              color: '#00599d',
              fontSize: 16
            },
            isSelected: true
          }}
        ));
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onDonePress = async () => {
    const selected = data.filter(item => item.isSelected);
    const param = selected.map((item) => {
      return {
        boughtDetailsId: item.id,
        umaga: timeId == 1 ? item.umaga : 0,
        tanghali: timeId == 2 ? item.tanghali : 0,
        gabi: timeId == 3 ? item.gabi : 0,
      }
    })

    await uploadTaken(param)
    
    if (props.onBackPress) {
      props.onBackPress();
    }
    navigate(-1);
  }

  const onRowPress = (rowData) => {
    if ((timeId == 1 && rowData.umaga_taken == 1)
      || (timeId == 2 && rowData.tanghali_taken == 1)
      || (timeId == 3 && rowData.gabi_taken == 1))
    {
      //do nothing 
    }
    else {
      let tempGamot = [...data
      .map(item => {
        if (item.id == rowData.id) {
          return {
            ...item,
            isSelected: !rowData.isSelected
          }
        }
        else {
          return item
        }
      })];
      setData(tempGamot);
    }
  }

  useEffect(() => {
    setData([...sku.filter(item => (timeId == 1 && item.umaga > 0) || 
      (timeId == 2 && item.tanghali > 0) ||
      (timeId == 3 && item.gabi > 0)
    ).map((item) => {
      return {
        ...item,
        line1: {
          text: `${item.brand.name}\n(${item.generic.name} ${item.dosage.name})`,
          color: '#00599d',
          fontSize: 18
        },
        line2: {
          // text: this._getWhen(),
          text: item.remarks,
          color: '#000000',
          fontSize: 16
        },
        line3: {
          text: item.dosage.name,
          color: '#00599d',
          fontSize: 16
        },
        isSelected: true
      }}
    )])
  }, [timeId])

  const tabAction = (index) => {
    console.log(`index=${index}`)
    let arr = tabArr
    arr.map((item, i) =>
        arr[i].isSelect = false
    )
    arr[index].isSelect = true
    setTimeId(index + 1);
    setTabArr(arr);
  }
  
  return (
    (typeof props.dashData !== 'undefined' && props.dashData.length > 0 && props.dashData[0].received == 1) ? 
      <div className={classes.uminomContainer}>
        {loader && <Loader />}
        <div className={classes.uminomContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661330512768.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
              <div style={{display: "flex", alignItems: 'flex-start', flexDirection:'row',width:'100%', borderRadius: 3, 
                backgroundColor: '#71a2c8', height: 40, marginTop: 10, zIndex: 100}}>
                {
                    tabArr.map((item,index)=> {
                        const indicator = item.isSelect ? <div className={classes.indicator} onClick={()=>tabAction(index)}/> : null;
                        return <div key={index} className={item.isSelect ? classes.selectedBtn : classes.unSelectbtn} 
                            onClick={()=>tabAction(index)}>
                            <p className={classes.label} onClick={()=>tabAction(index)}>{item.tabName}</p>
                            {indicator}
                        </div>
                    })
                }
              </div>
            </div>
            <div style={{marginTop: 10, marginLeft: 50, minHeight: 200}}>
              {data.map((rowData,index) => {
                const w = 300;
                const h = 180;
                return <div key={rowData.id} style={{display: "flex", width: w, height: h, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                  <div
                    style={{display: 'flex', width: w * 0.9, height: h * 0.8, position: 'absolute', backgroundColor: rowData.isSelected ? '#B0E0E6' : '#B0E0E670', borderRadius: 6, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
                    onClick={() => onRowPress(rowData)} 
                  >
                    <p style={{marginTop: 15, textAlign: 'center', fontWeight: '500', width: w * 0.8, color: rowData.line1.color, fontSize: rowData.line1.fontSize}} >{rowData.line1.text}</p>
                    { typeof rowData.line2 !== 'undefined' && <p style={{textAlign: 'center', width: w * 0.8, color: rowData.line2.color, fontSize: rowData.line2.fontSize}} >{rowData.line2.text}</p> }
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8}}>
                      <p style={{color: rowData.line3.color, fontSize: rowData.line3.fontSize}} >Dosage: </p>
                      <p style={{marginLeft: 5, color: '#000000', fontSize: rowData.line3.fontSize}} >{timeId == 1 ? rowData.umaga : timeId == 2 ? rowData.tanghali : rowData.gabi} {(timeId == 1 ? rowData.umaga : timeId == 2 ? rowData.tanghali : rowData.gabi) == 1 ? 'tab' : 'tabs'}</p>
                    </div>
                    {(rowData.isSelected || (timeId == 1 && rowData.umaga_taken == 1)
                      || (timeId == 2 && rowData.tanghali_taken == 1)
                      || (timeId == 3 && rowData.gabi_taken == 1)) && <img
                        style={{width: 20, height: 20, position: 'absolute', right: 3, top: '5%'}}
                        src={checkButton}
                      />
                    }
                  </div>
                </div>
              })}
            </div>
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 30, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {timeId == 1 && data.filter(item => item.umaga_taken).length < data.length &&
              <p style={{color: 'black', bottom: 30, fontWeight: '500', fontSize: 20}}>Did you take your medicines?</p>
            }
            {timeId == 2 && data.filter(item => item.tanghali_taken).length < data.length &&
              <p style={{color: 'black', bottom: 30, fontWeight: '500', fontSize: 20}}>Did you take your medicines?</p>
            }
            {timeId == 3 && data.filter(item => item.gabi_taken).length < data.length &&
              <p style={{color: 'black', bottom: 30, fontWeight: '500', fontSize: 20}}>Did you take your medicines?</p>
            }
            { data.filter(item => item.isSelected).length > 0 &&
              ((timeId == 1 && data.filter(item => item.umaga_taken).length < data.length) ||
                (timeId == 2 && data.filter(item => item.tanghali_taken).length < data.length) ||
                (timeId == 3 && data.filter(item => item.gabi_taken).length < data.length)
              ) && 
              <div onClick={() => onDonePress()} style={{bottom: 0, cursor: 'pointer'}} >
                <MyButton title='Yes'/>
              </div>
            }
            { data.length > 0 && data.filter(item => item.isSelected).length == 0 &&
              <div style={{bottom: 80}} >
                <p style={{fontStyle: 'italic', fontWeight: '400', fontSize: 18}}>Paki tap ang mga iinuming gamot</p>
              </div>
            }
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            {/* {props.userData.isTeamLeader ? 
              <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}
                rightButtonLabel="TEAM"
                onRightPress={() => navigate(routes.tlUminom, { state: {userData: props.userData, dashData: props.dashData} })}
              /> : */}
              <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
            {/* } */}
          </div>
        </div>
      </div>
    : isLoaded ? 
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <img src={getPlaceHolder(props.dashData)} style={{width: 300, height: 591, marginTop: 50}} onClick={() => navigate(-1)}/>
        <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', minWidth: 400}}>
          {/* {props.userData.isTeamLeader ? 
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}
              rightButtonLabel="TEAM"
              onRightPress={() => navigate(routes.tlUminom, { state: {userData: props.userData, dashData: props.dashData} })}
            /> : */}
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
          {/* } */}
        </div>
      </div>
    : null
  );
};

export default Uminom;