import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {formatNumberWithComma, getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Uminom_1.png';
import checkButton from '../../../assets/images/common-images/checkIcon.png';
import innerYes from '../../../assets/images/common-images/oo_inom.png';
import Done from '../../../assets/images/common-images/done.png'
import Plus from '../../../assets/images/common-images/plusIcon.png'
import Minus from '../../../assets/images/common-images/minusIcon.png'
import {
  Form,
  FormControl
} from "react-bootstrap";

import {
  getReseta
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import MyButton from '../../../Components/Common/MyButton';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Cart = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [level, setLevel] = useState(0);
  const [sku, setSku] = useState([]);
  const [ds, setDs] = useState([]);
  const [dsData, setDsData] = useState([]);
  const [selectedDs, setSelectedDs] = useState();
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const props = useLocation().state;

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const fetchData = () => {
    setSku([
      {
        id: 1,
        generic: {
          id: 1,
          name: 'Losartan'
        },
        brand: {
          id: 1,
          name: 'TGP',
          price: 5
        },
        dosage: {
          id: 1,
          name: '50mg'
        },
        quantity: 10,
        isSelected: true
      },
      {
        id: 2,
        generic: {
          id: 2,
          name: 'Atorvastatin'
        },
        brand: {
          id: 2,
          name: 'TGP',
          price: 12
        },
        dosage: {
          id: 2,
          name: '20mg'
        },
        quantity: 10,
        isSelected: true
      },
    ])
    setDsData([
      {
        id: 100,
        name: "TGP",
        imageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661497448987.png',
        location: {
          id: 1,
          name: 'Puregold Balara',
          address: `Kiosks NO. 11-12 Puregold Price Club, Inc. - Commonwealth Avenue, Matandang Balara, Quezon City, 1119`
        },
        details: [
          {
            id: 1,
            generic: {
              id: 1,
              name: 'Losartan'
            },
            brand: {
              id: 1,
              name: 'TGP',
              price: 5,
            },
            dosage: {
              id: 1,
              name: '50mg'
            },
          },
          {
            id: 2,
            generic: {
              id: 2,
              name: 'Atorvastatin'
            },
            brand: {
              id: 2,
              name: 'TGP',
              price: 12,
            },
            dosage: {
              id: 2,
              name: '20mg'
            },           
          },
          {
            id: 3,
            generic: {
              id: 3,
              name: 'Metformin',
            },
            brand: {
              price: 3,
            },
            dosage: {
              id: 3,
              name: '500mg'
            },
          }
        ]
      },
      // {
      //   id: 101,
      //   name: 'Mercury',
      //   location: {
      //     id: 2,
      //     name: "Ever Commonwealth"
      //   },
      //   details: [
      //     {
      //       id: 1,
      //       generic: {
      //         id: 1,
      //         name: 'Losartan'
      //       },
      //       brand: {
      //         price: 20
      //       },
      //       dosage: {
      //         id: 1,
      //         name: '50mg'
      //       },
      //     },
      //     {
      //       id: 2,
      //       generic: {
      //         id: 2,
      //         name: 'Atorvastatin'
      //       },
      //       brand: {
      //         price: 38.50
      //       },
      //       dosage: {
      //         id: 2,
      //         name: '20mg'
      //       },
      //     },
      //     {
      //       id: 3,
      //       generic: {
      //         id: 3,
      //         name: 'Metformin',
      //       },
      //       brand: {
      //         price: 3.5,
      //       },
      //       dosage: {
      //         id: 3,
      //         name: '500mg'
      //       },
      //     }
      //   ]
      // },
      {
        id: 102,
        name: "Watsons",
        imageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661497395783.png',
        location: {
          id: 2,
          name: "Berkeley Square",
          address: 'Berkeley Square, 869 Commonwealth Ave, Matandang Balara, Quezon City, 1119 Metro Manila'
        },
        details: [
          {
            id: 1,
            generic: {
              id: 1,
              name: 'Losartan'
            },
            brand: {
              id: 3,
              name: "WG",
              price: 20
            },
            dosage: {
              id: 1,
              name: '50mg'
            },
          },
          {
            id: 2,
            generic: {
              id: 2,
              name: 'Atorvastatin'
            },
            brand: {
              id: 3,
              name: "WG",
              price: 39
            },
            dosage: {
              id: 2,
              name: '20mg'
            },
          },
          {
            id: 3,
            generic: {
              id: 3,
              name: 'Metformin',
            },
            brand: {
              price: 3.5,
            },
            dosage: {
              id: 3,
              name: '500mg'
            },
          }
        ]
      }
    ])
  }

  useEffect(() => {
    fetchData();
  }, []);

  const onRowPress = (rowData) => {
    setSelectedOption(rowData);
    setLevel(10);
  }

  const addQty = (e, rowData) => {
    e.stopPropagation();
    let tempGamot = [...sku
    .map(item => {
      if (item.id == rowData.id && item.quantity < 100) {
        return {
          ...item,
          quantity: item.quantity + 1
        }
      }
      else {
        return item
      }
    })];
    setSku(tempGamot);
  }

  const minusQty = (e, rowData) => {
    e.stopPropagation();
    let tempGamot = [...sku
    .map(item => {
      if (item.id == rowData.id && item.quantity > 0) {
        return {
          ...item,
          quantity: item.quantity - 1
        }
      }
      else {
        return item
      }
    })];
    setSku(tempGamot);
  }

  const onConfirmPress = () => {
    navigate(-1)
  }

  const onDsRowPress = (rowData) => {
    setSelectedDs(rowData);
    setLevel(2);
  }

  const onDrugstorePress = () => {
    let data = [];
    dsData.map(store => {
      let total = 0;
      let details = [];
      sku.filter(item => item.isSelected).map(selected => {
        const storeItem = store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id)
        if (storeItem) {
          total += storeItem.brand.price * selected.quantity;
          details.push({
            ...storeItem,
            quantity: selected.quantity
          })
        }
      })
      data.push({
        ...store,
        total,
        details: [...details]
      })
    })
    setDs(data);
    setLevel(1);
  }

  const addQtyDs = (e, rowData) => {
    e.stopPropagation();
    let tempGamot = [...ds
    .map(store => {
      let total = 0;
      const details = store.details.map(item => {
        if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity < 30) {
          total += item.brand.price * (item.quantity + 1);
          return {
            ...item,
            quantity: item.quantity + 1
          };
        }
        else {
          total += item.brand.price * item.quantity;
          return item
        }
      })
      return {
        ...store,
        details: details,
        total: total
      }
    })];
    const selected = tempGamot.filter(item => item.id == selectedDs.id)
    if (selected.length > 0) {
      setSelectedDs(selected[0]);
    }
    setDs(tempGamot);
  }

  const minusQtyDs = (e, rowData) => {
    e.stopPropagation();
    let tempGamot = [...ds
    .map(store => {
      let total = 0;
      const details = store.details.map(item => {
        if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity > 0) {
          total += item.brand.price * (item.quantity - 1);
          return {
            ...item,
            quantity: item.quantity - 1
          }
        }
        else {
          total += item.brand.price * item.quantity;
          return item
        }
      })
      return {
        ...store,
        details: details,
        total: total
      }
    })];
    const selected = tempGamot.filter(item => item.id == selectedDs.id)
    if (selected.length > 0) {
      setSelectedDs(selected[0]);
    }
    setDs(tempGamot);
  }

  const onDsPress = () => {
    setLevel(11);
  }

  const w = 400;
  const h = 200;

  return (
    <div className={classes.cartContainer}>
      {loader && <Loader />}
      {level == 0 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661407511694.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 20, width: '90%', minHeight: 280, backgroundColor: '#B0E0E6', borderRadius: 20}}>
              {sku.map((rowData,index) => {
                return <div key={rowData.id} style={{display: "flex", width: w, paddingTop: 20, height: 80, flexDirection: 'column', justifyContent: 'center', 
                  alignItems: 'flex-start', cursor: 'pointer'}} onClick={() => onRowPress(rowData)}>
                  
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.85, height: 30, marginBottom: 5}}>
                    <p style={{marginLeft: 15, color: '#00599d', fontSize: 20, width: '65%', textAlign: 'left'}} >{rowData.generic.name} {rowData.dosage.name}</p>
                    <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '35%', marginLeft: 5, color: '#00599d', textAlign: 'right'}}>₱{formatNumberWithComma(rowData.brand.price * rowData.quantity, 2)}</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.85, height: 30, marginBottom: 5}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '60%'}}>
                      <p style={{color: '#00599d', fontSize: 18, textAlign: 'left', marginTop: 5, marginLeft: 15}} >Brand:</p>
                      <p style={{marginLeft: 5, color: 'black', fontSize: 20, textAlign: 'left', marginTop: 5}} >{rowData.brand.name}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '40%', height: 30, marginTop: 0, marginLeft: 40}}>
                      <p style={{color: '#00599d', fontSize: 18, textAlign: 'left', marginTop: 5}} >Qty:</p>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '40%', marginTop: 0, marginLeft: 15}}>
                        <div style ={{cursor: 'pointer', width: 25, height: 30, zIndex: 1}} onClick={(e) => addQty(e, rowData)}>
                          <img src={Plus} style={{widht: 20, height: 20, marginRight: 5}}/>
                        </div>
                        <p style={{height: 18, fontSize: 20, height: 25}}>{rowData.quantity}</p>
                        <div style ={{cursor: 'pointer', width: 25, height: 30, zIndex: 1}} onClick={(e) => minusQty(e, rowData)}>
                          <img src={Minus} style={{widht: 20, height: 20, marginLeft: 5}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })}
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#00599d', fontSize: 28, height: 15, marginTop: 20}} >Total Price:</p>
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 50, height: 20}} >₱{formatNumberWithComma(sku.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
            </div>
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            { sku.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ) > 0 &&
              <div onClick={() => onConfirmPress()} style={{cursor: 'pointer'}} >
                <MyButton title="Confirm"/>
              </div>
            }
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.home)} title="" style={{position: 'absolute'}}
              hasDrugstoreBtn
              onDrugstorePress={() => onDrugstorePress()}
            />
          </div>
        </div>
      }
      {level == 1 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661246362677.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 0, minHeight: 200}}>
              {ds.map((rowData,index) => {
                return <div key={index} style={{display: "flex", width: w, height: h, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                  <div
                    style={{cursor: 'pointer', display: 'flex', width: w * 0.9, height: h * 0.8, position: 'absolute', backgroundColor: '#B0E0E6', borderRadius: 20, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
                    onClick={() => onDsRowPress(rowData)} 
                  >
                    <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 24, height: 20}} >{rowData.name}</p>
                    <p style={{textAlign: 'center', width: w * 0.8, color: 'black', fontSize: 20}} >{rowData.location.name} Branch</p>
                    <div style={{display: 'flex', flexDirection: 'row', width: w * 0.8, alignItems: 'center', justifyContent: 'center'}}>
                      <p style={{textAlign: 'center', fontWeight: '500', color: '#00599d', fontSize: 22, height: 20}} >Total Price:</p>
                      <p style={{textAlign: 'center', fontWeight: '500', color: 'black', fontSize: 22, height: 20, marginLeft: 5}} >₱{formatNumberWithComma(rowData.total,2)}</p>
                    </div>
                  </div>
                </div>
              })}
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => {
              setLevel(0);
              setSelectedDs(null);
            }} title="" style={{position: 'absolute'}} />
          </div>
        </div>
      }
      {level == 2 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661309091920.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            {/* <div style={{marginTop: 10, marginLeft: 0, minHeight: 200}}>
              {selectedDs.details.map((rowData,index) => {
                return <div key={rowData.id} style={{display: "flex", width: w, height: h, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                  <div
                    style={{display: 'flex', width: w * 0.9, height: h * 0.9, position: 'absolute', backgroundColor: '#B0E0E6', borderRadius: 20, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
                    onClick={() => onRowPress(rowData)} 
                  >
                    <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 24}} >{rowData.generic.name} {rowData.dosage.name}</p>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                      <div style ={{cursor: 'pointer', width: 30, height: 30, zIndex: 1}} onClick={(e) => addQtyDs(e, rowData)}>
                        <img src={Plus} style={{widht: 20, height: 20, marginRight: 10}}/>
                      </div>
                      <p style={{height: 18, fontSize: 20, height: 20}}>{rowData.quantity} {rowData.quantity == 1 ? 'tab' : 'tabs'}</p>
                      <div style ={{cursor: 'pointer', width: 30, height: 30, zIndex: 1}} onClick={(e) => minusQtyDs(e, rowData)}>
                        <img src={Minus} style={{widht: 20, height: 20, marginLeft: 10}} />
                      </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', width: w * 0.8, alignItems: 'center', justifyContent: 'center'}}>
                      <p style={{height: 18, color: '#00599d', fontSize: 20, marginTop: 5}}>Price:</p>
                      <p style={{height: 18, color: 'black', fontSize: 20, marginTop: 5, marginLeft: 5}}>₱{formatNumberWithComma(rowData.brand.price, 2)}/tab</p>
                    </div>
                  </div>
                </div>
              })}
            </div> */}
            <div
              style={{marginTop: 10, paddingTop: 10, marginLeft: 20, cursor: 'pointer', display: 'flex', width: w * 0.9, height: h * 0.4, 
                backgroundColor: '#B0E0E670', borderRadius: 20, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'
              }}
              onClick={() => onDsPress()} 
            >
              <img src={selectedDs.imageBig} style={{width: h * 0.3, height: h * 0.3, marginBottom: 5}} />
              <div style={{display: 'flex', flexDirection: 'column', width: w * 0.6, justifyContent: 'center', alignItems: 'center', paddingTop: 5}}>
                <p style={{textAlign: 'center', fontWeight: '500', color: '#00599d', fontSize: 24, height: 20}} >{selectedDs.name}</p>
                <p style={{textAlign: 'center', color: 'black', fontSize: 20}} >{selectedDs.location.name} Branch</p>
              </div>
            </div>
            <div style={{marginTop: 10, marginLeft: 20, width: '90%', minHeight: 280, backgroundColor: '#B0E0E6', borderRadius: 20}}>
              {selectedDs.details.map((rowData,index) => {
                return <div key={rowData.id} style={{display: "flex", width: w, paddingTop: 20, height: 80, flexDirection: 'column', justifyContent: 'center', 
                  alignItems: 'flex-start', cursor: 'pointer'}} onClick={() => onRowPress(rowData)}>
                  
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.85, height: 30, marginBottom: 5}}>
                    <p style={{marginLeft: 15, color: '#00599d', fontSize: 20, width: '65%', textAlign: 'left'}} >{rowData.generic.name} {rowData.dosage.name}</p>
                    <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '35%', marginLeft: 5, color: '#00599d', textAlign: 'right'}}>₱{formatNumberWithComma(rowData.brand.price * rowData.quantity, 2)}</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.85, height: 30, marginBottom: 5}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '60%'}}>
                      <p style={{color: '#00599d', fontSize: 18, textAlign: 'left', marginTop: 5, marginLeft: 15}} >Brand:</p>
                      <p style={{marginLeft: 5, color: 'black', fontSize: 20, textAlign: 'left', marginTop: 5}} >{rowData.brand.name}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '40%', height: 30, marginTop: 0, marginLeft: 40}}>
                      <p style={{color: '#00599d', fontSize: 18, textAlign: 'left', marginTop: 5}} >Qty:</p>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '40%', marginTop: 0, marginLeft: 15}}>
                        <div style ={{cursor: 'pointer', width: 25, height: 30, zIndex: 1}} onClick={(e) => addQty(e, rowData)}>
                          <img src={Plus} style={{widht: 20, height: 20, marginRight: 5}}/>
                        </div>
                        <p style={{height: 18, fontSize: 20, height: 25}}>{rowData.quantity}</p>
                        <div style ={{cursor: 'pointer', width: 25, height: 30, zIndex: 1}} onClick={(e) => minusQty(e, rowData)}>
                          <img src={Minus} style={{widht: 20, height: 20, marginLeft: 5}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })}
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#00599d', fontSize: 28, height: 15, marginTop: 20}} >Total Price:</p>
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 50, height: 20}} >₱{formatNumberWithComma(selectedDs.details.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
            </div>
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div onClick={() => navigate(routes.home)} style={{cursor: 'pointer'}} >
              <MyButton title='Confirm'/>
            </div>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => setLevel(1)} title="" style={{position: 'absolute'}} />
          </div>
        </div>
      }
      {level == 10 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: '#cce8fe', top: 30, marginLeft: '5%', alignItems: 'center', 
              flexDirection: 'column', width: '90%', minHeight: 200, borderRadius: 8 }}
            >
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 28, height: 32, marginTop: 20}} >{selectedOption.generic.name} {selectedOption.dosage.name}</p>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, marginLeft: w * 0.15, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Brand: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>{selectedOption.brand.name}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, marginLeft: w * 0.15, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Quantity: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>{selectedOption.quantity}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, marginLeft: w * 0.15, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Unit Price: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>₱{formatNumberWithComma(selectedOption.brand.price, 2)}/tab</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, marginLeft: w * 0.15, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Total: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>₱{formatNumberWithComma(selectedOption.brand.price * selectedOption.quantity, 2)}</p>
              </div>
            </div>
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div onClick={() => setLevel(selectedDs ? 2 : 0)} style={{cursor: 'pointer'}} >
              <MyButton title="Back"/>
            </div>
          </div> 
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader goBackClick={() => {
              setLevel(selectedDs ? 2 : 0)
            }} title="" style={{position: 'absolute'}}
              hasBackBtn
            />
          </div>
        </div>
      }
      {level == 11 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: '#cce8fe', top: 30, marginLeft: '5%', alignItems: 'center', 
              flexDirection: 'column', width: '90%', minHeight: 340, borderRadius: 8 }}
            >
              <img src={selectedDs.imageBig} style={{width: h * 0.5, height: h * 0.5, marginTop: 15}} />
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 28, height: 32, marginTop: 20}} >{selectedDs.name}</p>
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: 'black', fontSize: 24, height: 32, marginTop: 0}} >{selectedDs.location.name} Branch</p>
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: 'black', fontSize: 16, height: 32, marginTop: 0}} >{selectedDs.location.address}</p>
              {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, marginLeft: w * 0.15, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Brand: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>{selectedOption.brand.name}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, marginLeft: w * 0.15, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Quantity: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>{selectedOption.quantity}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, marginLeft: w * 0.15, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Unit Price: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>₱{formatNumberWithComma(selectedOption.brand.price, 2)}/tab</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, marginLeft: w * 0.15, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Total: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>₱{formatNumberWithComma(selectedOption.brand.price * selectedOption.quantity, 2)}</p>
              </div> */}
            </div>
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div onClick={() => setLevel(selectedDs ? 2 : 0)} style={{cursor: 'pointer'}} >
              <MyButton title="Back"/>
            </div>
          </div> 
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader goBackClick={() => {
              setLevel(selectedDs ? 2 : 0)
            }} title="" style={{position: 'absolute'}}
              hasBackBtn
            />
          </div>
        </div>
      }
    </div>
  );
};

export default Cart;

