import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import MyButton from "../../Components/Common/MyButton";
import { routes } from "../../constants";
import { login } from "../../services/ApiService";
import Logo from "../../assets/images/logo/main_logo.png";
import Egg from "../../assets/images/logo/egg.jpeg";
import { getIsLoggedIn, DappUI } from "@elrondnetwork/dapp-core";
// import Sound from "../../assets/sounds/welcome.mp3";
import ThankYou from '../../assets/images/common-images/thank_you.png';
import Done from '../../assets/images/common-images/tumuloy.png'
import CheckBoxCheck from '../../assets/images/common-images/checkBoxCheck.png';
import {
  Form,
  FormControl
} from "react-bootstrap";
import {
  checkRxLogin
} from "../../services/ApiService";
import {
  isMobile,
  browserName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
} from "react-device-detect";
import "./style.css";
import "./styles.css";
import useStyles from './styles';
import InnerPageHeader from "../../Components/InnerPageHeader";
// import Maiar from "../../assets/images/logo/maiar.png";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import {
  uploadMedia
} from "../../services/ApiService";

const LoginPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { WalletConnectLoginButton } = DappUI;
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [level, setLevel] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [scIdNo, setScIdNo] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [tabArr, setTabArr] = useState([
    { tabId: 1, tabName: 'Agahan', isSelect: true },
    { tabId: 2, tabName: 'Tanghalian', isSelect: false },
    { tabId: 3, tabName: 'Hapunan', isSelect: false },
  ])
  const [timeId, setTimeId] = useState(1);
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
      [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
      onWindowResize = () => {              
        clearTimeout(window.resizeLag)
        window.resizeLag = setTimeout(() => {
          delete window.resizeLag                       
          setOrientation(isLandscape() ? 'landscape' : 'portrait')
        }, 200)
      }

  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
  ),[])

  const isLoggedIn = getIsLoggedIn();
  useEffect(() => {
    if (isLoggedIn) {
      console.log("loggg", isLoggedIn);
      // localStorage.removeItem("persist:dapp-core-store");
      localStorage.setItem("isNewUser", "0");
      const user =
        localStorage.getItem("token") &&
        JSON.parse(localStorage.getItem("token"));
      if (user) {
        // window.location.href = routes.home;
        navigate(routes.home);
      }
    }
  }, [isLoggedIn]);

  // const loginHandler = async (oldId, apiKey, wefftWalletAddress) => {
    const loginHandler = async (oldId, apiKey) => {
    try {
      setLoader(true);
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        setLoader(false);
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        // localStorage.setItem("wefftWalletAddress", wefftWalletAddress);
        // localStorage.setItem("maiarAddress", wefftWalletAddress);
        localStorage.setItem("footerIndex", 0);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        localStorage.setItem("isNewUser", "0");
        localStorage.setItem("autoJoin", "0");
        navigate(routes.home);
        // window.open(routes.home);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const onDonePress = async (e) => {
    if (code.trim() == "") {
      setErrorMsg('Invalid Code');
    }
    else if (password.trim() == "" || password.length != 6) {
      setErrorMsg('Invalid Password');
    }
    else {
      window.scrollTo(0, 0);
      try {
        const response = await checkRxLogin({code: code, birthdate: password});
        if (response) {
          if (response.data.length > 0) {
            loginHandler(response.data[0].oldId, response.data[0].apiKey)
            return;
          }
          else {
            setErrorMsg('Invalid Code or Password')
          }
        }
      } catch (e) {
        console.log(e);
        setErrorMsg('Invalid Code or Password')
      }
    }
  }

  const handleFile = async (file) => {
    const formData = new FormData();
    if (!file) {
      window.alert(`!File`);
      return;
    }
    formData.append("file", file);

    const response = await uploadMedia(formData);
    // console.log("response", JSON.stringify(response));
    try {
      // const upload = await uploadResetaImage({imageBig: response.data.data[0].imageUrlBigUrl})
      // if (upload) {
      //   // console.log(`upload.response=${JSON.stringify(upload)}`)
      //   await fetchData();
      // }
    } catch (e) {
      console.log(e);
    }
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    const mArray = fileUploaded.name.split(".");
    const fileExtension = mArray[mArray.length - 1];
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File type not supported.`);
    }
    else {
      handleFile(fileUploaded);
    }
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const onTumuloyPress = () => {
    setLevel(2);
  }

  const medOptions = [
    { value: 1, label: 'Losartan' },
    { value: 2, label: 'Atorvastatin' },
    { value: 3, label: 'Metformin' },
  ];
  const defaultMedOption = medOptions[0];


  const dosOptions = [
    { value: 1, label: '20mg' },
    { value: 2, label: '50mg' },
    { value: 3, label: '500mg' },
  ];
  const defaultDosOption = dosOptions[0];

  const _onSelect = () => {

  }

  const tabAction = (index) => {
    let arr = tabArr
    arr.map((item, i) =>
        arr[i].isSelect = false
    )
    arr[index].isSelect = true
    setTimeId(index + 1);
    setTabArr(arr);
  }

  return (
    <>
    {level == 0 && 
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "90vh",
          flexDirection: "column",
        }}
      >
        {loader && <Loader />}
        <img
          src={Logo}
          style={{
            width: 180,
            height: 180,
            borderRadius: "50%",
            objectFit: "cover",
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        <div style={{display: 'flex', flexDirection: 'column', marginTop: 10, height: 360, alignItems: 'center'}}>
          <div style={{width: 300, height: 360, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
            <div
              style={{display: 'flex', flexDirection: 'column', width: 300, height: 360, position: 'absolute', backgroundColor: '#B0E0E6', borderRadius: 6, justifyContent: 'flex-start', alignItems: 'center'}}
            >
              <p style={{textAlign: 'left', paddingLeft: 30, fontWeight: '500', marginTop: 30, width: 300, color: 'black', fontSize: 22}} >Code:</p>
              <Form inline>
                <FormControl
                  style={{ width: 240, fontSize: 20 }}
                  type="text"
                  maxLength={20}
                  value={code}
                  placeholder="Enter your code"
                  onChange={(e) => {
                    setCode(e.target.value)
                    setErrorMsg("")
                  }}
                />{" "}
              </Form>
              <p style={{textAlign: 'left', paddingLeft: 30, fontWeight: '500', marginTop: 30, width: 300, color: 'black', fontSize: 24}} >Password:</p>
              <Form inline>
                <FormControl
                  style={{ width: 240, fontSize: 20 }}
                  type="password"
                  minLength={6}
                  maxLength={6}
                  value={password}
                  placeholder="Enter your password"
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setErrorMsg("")
                  }}
                />{" "}
              </Form>
              <div onClick={() => onDonePress()} style={{position: 'absolute', bottom: 30, cursor: 'pointer'}} >
                <MyButton title='Login' />
              </div>
              <div onClick={() => setLevel(1)} style={{position: 'absolute', right: 10, bottom: 0, cursor: 'pointer'}} >
                <p style={{fontSize: 14, textDecoration: 'underline'}}>New User</p>
              </div>
              <p style={{textAlign: 'center', width: 300, color: 'red', fontSize: 14, position: 'absolute', bottom: 0}} >{errorMsg}</p>
            {/*<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: w * 0.8}}> 00599d
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8}}>
                { choice.map(item => {
                  return <div key={item.id} style={{cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: w * 0.8, alignItems: 'center'}} onClick={() => setSelectedId(item.id)} >
                    <p style={{color: '#000000', fontSize: 40}} >{item.name}</p>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 40, height: 40, borderWidth: 2, border: '3px solid #000000', borderRadius: 3, marginRight: 5, marginBottom: 10}}>
                      {item.id == selectedId && <img src={CheckBoxCheck} style={{width: 40, height: 40}}/>}
                    </div>
                  </div>
                }) }
              </div> */}
            </div>
          </div> 
        </div>
        {/* { Number(process.env.REACT_APP_FAKE_LOGIN) === 1 && <div
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <button
            onClick={() =>
              loginHandler(
                "9a5619b0-d862-11ea-882e-0242ac110010", //kevin 68202
                "iWzY1b7WZurzvOSEgJPVLphe2I5M25qj",
                "erd1e2rfnsg2dlqsaf5q7qmeweuxkt8rshe2jdnwce87rend4crax0vq0nrzu7"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 160,
              color: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
              fontSize: '14px'
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            000500
          </button> 
          <button
            onClick={() =>
              loginHandler(
                "8881e630-d84c-11ea-8cac-0242ac110010", //herbert  60395
                "xLCNwKfVDcUJSaNnwqKosPrD2lfAUp6r",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6ygzlszae7sn"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 160,
              color: "blue",
              marginLeft: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
              fontSize: '14px'
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            000501
          </button>
        </div> }
        {Number(process.env.REACT_APP_FAKE_LOGIN) === 1 && <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10
          }}
        >
          <button
            onClick={() =>
              loginHandler(
                "156f0f92-6f3b-43a0-990b-b596028ef931", //Jtest 131155
                "vxc7mzv61V1ZmjoWeNAiir",
                "erd1e2rfnsg2dlqsaf5q7qmeweuxkt8rshe2jdnwce87rend4crax0vq0dg63j"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 160,
              color: "purple",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
              fontSize: '14px'
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            JTest
          </button>
          <button
            onClick={() =>
              loginHandler(
                "e4582cb0-8ba5-11ea-a224-0242ac110008", // mro20 100875
                "1SKBAbcaAPbaE09Cyog9LrPXdZbcKhrF",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6ygzlszvxlc6"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 160,
              color: "black",
              marginLeft: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
              fontSize: '14px'
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            mro20
          </button> 
        </div>}

        {Number(process.env.REACT_APP_FAKE_LOGIN) === 1 && <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10
          }}
        >
          <button
            onClick={() =>
              loginHandler(
                "92d3ae60-725e-11ea-89b2-0242ac110008",
                "KgbJBRPxj5IvwYUy898DW4FUcmyrPatR",
                "erd1ht8kvgxmjxvy8wjhnzsenje0l26sn2pzwrfhmw3yjhyy5fk23cpqferrnl"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 160,
              color: "green",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
              fontSize: '14px'
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 5 (A)
          </button>
          <button
            onClick={() =>
              loginHandler(
                "13eac760-725d-11ea-89b2-0242ac110008",
                "JREFlHu7RB92CawjM5hp5k0uPh9JUptq",
                "erd1y797wexf3a38u7g64368yydgewpxheywwl3k5c3anta2297kruaq28qtpj"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 160,
              color: "brown",
              marginLeft: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
              fontSize: '14px'
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 6 (B)
          </button> 
        </div> } */}
        {/* <button
            onClick={() =>
              loginHandler(
                "0060a390-b659-11eb-8e20-924d266ce740",
                "Jz2vFofki3wjBLbOmBwuQz1tlw9lI9GT",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzntpuglk"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 7
          </button>
          <button
            onClick={() =>
              loginHandler(
                "00614020-f470-11eb-bf89-aa9e76aedffd",
                "iJjI7W3X7BIXRAcGisD7GmtY2ANtCkWl",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzwquec6r"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 8
          </button>
          <button
            onClick={() =>
              loginHandler(
                "00614400-d880-11eb-a293-3ad15f7fd6a1",
                "YLmi8Ne0J2PzsrsqrRWXLHOPbRqsudXb",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzuencpeh"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 9
          </button>
          <button
            onClick={() =>
              loginHandler(
                "00615960-dcc5-11eb-9892-168b6e73f4be",
                "wxl61427Jr7dmqw1DtO3oUNCn1VxKF2s",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzze4x1du1"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 10
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0061cbb0-f8aa-11eb-8827-d292e928ff1d",
                "d96ZjgUTRmTsZnhpoDPqMm01gR2p5B5u",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzhvx1gwu"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 11
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0061d8d0-0057-11ec-997b-12ddffe82d3a",
                "lqfbDUfGmVgz8n8Fobkba8dpG6bcPbZv",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzxnskqqd"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 12
          </button>
          <button
            onClick={() =>
              loginHandler(
                "00629880-ba00-11eb-b709-4a5ab3aa9b52",
                "fB6mbNkRZKTkq5d4ja2cktjSPgfouTa2",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzvxichqo"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 13
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0062e2d0-fead-11eb-9ead-faac3a0948c6",
                "omcDM06dA9DmUoA8A6HC10nsJKM7bolz",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzhw76p9r"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 14
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0063cde0-ca4f-11eb-a3a5-427dfbda9bef",
                "0i7TnAAg6vP4IekkRWGsaWdeWKwqHSaI",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzbrt5unw"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 15
          </button>
          <button
            onClick={() =>
              loginHandler(
                "00649e60-c774-11eb-ba14-aeab16f6b412",
                "yPDZVZQ52hENYqCQ1ohYWNUv3k5Dl1a6",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzcmfuy8l"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 16
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0064eec0-f2b1-11eb-8a95-36e6d441953c",
                "cOzlYw48fd5CPgteSncHqkq6kkfLg2gQ",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzk6mcigw"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 17
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0064fb70-ec32-11eb-897a-4618411deb55",
                "qhLjhSehIS1L17pmZeNCT2dkvz2zSFs9",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzziyu8buo"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 18
          </button>
          <button
            onClick={() =>
              loginHandler(
                "006531e0-f32a-11eb-8a95-36e6d441953c",
                "pQLXGeEoLMm38vWSTuVwafEcdoBfnLIW",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzvhhrvah"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 19
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0066f580-e938-11eb-897a-4618411deb55",
                "wHAKCf26z3klsPVgS364lKWftanEU2kV",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzhvetw7a"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 20
          </button>
          <button
            onClick={() =>
              loginHandler(
                "00670b30-f0fc-11eb-993a-e6771cdb9087",
                "zcrzDsmgU3gKGUmQLMbWe3hrvvuRyuVh",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzwvp1swo"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 21
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0067ccf0-c235-11eb-abce-6250ee276857",
                "nlcXtzoHk2wVMMFnsSUVHRcKlfjezaRZ",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzz2ddufbj"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 22
          </button>
          <button
            onClick={() =>
              loginHandler(
                "00681550-d507-11ea-a912-0242ac110010",
                "UQcmq07zkLYqBV3FadeYkEcyn8miyZNV",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzznq782aa"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 23
          </button>
          <button
            onClick={() =>
              loginHandler(
                "00681a50-e3cf-11eb-a293-3ad15f7fd6a1",
                "oyFkSBgVVuAGWXomH5EDRYRksycBTUKE",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzs3fgsot"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 24
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0068dfd0-c903-11eb-9d74-befc22f07fe8",
                "TldF5NUYFUJfrRMU7je9HoRWbtSkafBj",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzulk4uea"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 25
          </button>
          <button
            onClick={() =>
              loginHandler(
                "0069a090-d968-11ea-8eb3-0242ac110002",
                "2sjeoR7wjWXgn27ynx3AHXYYlfcPkSCK",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzz59vpgbp"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 26
          </button>
          <button
            onClick={() =>
              loginHandler(
                "006a3e60-d7b4-11eb-a293-3ad15f7fd6a1",
                "pE2ufvuFRBJPJqTnzWhB0ZqtEaLn7m1N",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzukofhpc"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 27
          </button>
          <button
            onClick={() =>
              loginHandler(
                "006ae5d0-bef6-11eb-be7f-06bb0ef06991",
                "r872aJkFYdsK2Nc7uWTYdw1DOnKuXDWv",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzkrasq3x"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 28
          </button>
          <button
            onClick={() =>
              loginHandler(
                "006b4360-c52a-11eb-bfdd-7ad97bb9ed3b",
                "u0bwTX6GxaobNj473zhKwuLKuyz6DBPX",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzz52115j2"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 29
          </button>
          <button
            onClick={() =>
              loginHandler(
                "006c0380-d3cc-11eb-9892-168b6e73f4be",
                "Yucg8ayiUsqgvCNKdnZt27lj0xMeGxm4",
                "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzz3vpsj90"
              )
            }
            style={{
              padding: "0.5rem 0.75rem",
              width: 200,
              color: "black",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              borderRadius: 25,
              backgroundColor: "white",
            }}
          >
            <img
              src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
              style={{
                width: 24,
                height: 24,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            Player 30
          </button> */}
          {/* <div className="bg_connect_maiar_btn">
            <WalletConnectLoginButton
              callbackRoute={routes.maiar}
              loginButtonText={`Connect to Maiar`}
              logoutRoute={routes.maiar}
              buttonClassName="button_maiar"
            />
          </div> */}
        <p style={{ color: "#db3eb1", marginTop: 20 }}>
          {process.env.REACT_APP_VERSION_PREFIX} {process.env.REACT_APP_VERSION}
        </p>
        {/* <video
          muted={false}
          playsInline
          preload="auto"
          src={Sound}
          loop
          autoPlay
          alt="icon"
          style={{ width: 0, height: 0 }}
        /> */}
        {!isMobile && (
          <p
            style={{
              color: "#404040",
              fontSize: 13,
              marginTop: 5,
              fontFamily: "CanaroBook",
            }}
          >
            *Best viewed on mobile browser
          </p>
        )}
        {isMobile && isIOS && !isSafari && (
          <p
            style={{
              color: "#404040",
              fontSize: 13,
              marginTop: 5,
              fontFamily: "CanaroBook",
            }}
          >
            *Best viewed on Safari
          </p>
        )}
        {isMobile && isAndroid && !isChrome && (
          <p
            style={{
              color: "#404040",
              fontSize: 13,
              marginTop: 5,
              fontFamily: "CanaroBook",
            }}
          >
            *Best viewed on Chrome
          </p>
        )}
        {(!isMobile || (isMobile && isIOS && !isSafari) || (isMobile && isAndroid && !isChrome)) && orientation == 'landscape' ? (
          <p
            style={{
              color: "#404040",
              fontSize: 13,
              marginTop: 0,
              fontFamily: "CanaroBook",
            }}
          >
            and in portrait mode
          </p>
        ) : orientation == 'landscape' && <p
          style={{
            color: "#404040",
            fontSize: 13,
            marginTop: 0,
            fontFamily: "CanaroBook",
          }}
        >
          *Best viewed in portrait mode
        </p>}
        {Number(process.env.REACT_APP_ON_BREAK) === 1 &&
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "100vh",
              flexDirection: "column",
              cursor: 'pointer'
            }}
            onClick={() => window.open('https://docs.google.com/spreadsheets/d/147w8LqW2uEFM9HZCHYx6HM2uvMHvXhTMlRI4ZgjN5JE/edit?fbclid=IwAR3DzZFU5Lqv-ZX8DEbArC8kY8A1RCchHjXSi8Yc6ipbCLcHu1rTx_WpY_M#gid=0', "_blank")}
          >
            <img src={ThankYou} style={{
              display: "flex",
              alignItems: "center",
              height: '100%',
              width: '100%',
              maxWidth: '480px',
              position: 'absolute',
              left: 'auto',
              top: 0
            }} />
          </div>
        }
      </div>
    }
    {level == 1 &&
      <div className={classes.loginContainer}>
        <div className={classes.loginContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660980613667.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
              <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>First Name:</p>
              <Form inline>
                <FormControl
                  style={{ width: 240, fontSize: 20, color: '#00599d' }}
                  type="text"
                  maxLength={30}
                  value={firstName}
                  placeholder=""
                  onChange={(e) => {
                    setFirstName(e.target.value)
                    setErrorMsg("")
                  }}
                />{" "}
              </Form>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
              <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>Last Name:</p>
              <Form inline>
                <FormControl
                  style={{ width: 240, fontSize: 20, color: '#00599d' }}
                  type="text"
                  maxLength={30}
                  value={lastName}
                  placeholder=""
                  onChange={(e) => {
                    setLastName(e.target.value)
                    setErrorMsg("")
                  }}
                />{" "}
              </Form>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
              <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>Birthday:</p>
              <Form inline>
                <FormControl
                  style={{ width: 240, fontSize: 20, color: '#00599d' }}
                  type="text"
                  maxLength={30}
                  value={birthday}
                  placeholder=""
                  onChange={(e) => {
                    setBirthday(e.target.value)
                    setErrorMsg("")
                  }}
                />{" "}
              </Form>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
              <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>Address:</p>
              <Form inline>
                <FormControl
                  style={{ width: 240, fontSize: 20, color: '#00599d' }}
                  type="text"
                  maxLength={30}
                  value={address}
                  placeholder=""
                  onChange={(e) => {
                    setAddress(e.target.value)
                    setErrorMsg("")
                  }}
                />{" "}
              </Form>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
              <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>Contact No.:</p>
              <Form inline>
                <FormControl
                  style={{ width: 240, fontSize: 20, color: '#00599d' }}
                  type="text"
                  maxLength={30}
                  value={contactNumber}
                  placeholder=""
                  onChange={(e) => {
                    setContactNumber(e.target.value)
                    setErrorMsg("")
                  }}
                />{" "}
              </Form>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
              <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>SC ID No.:</p>
              <Form inline>
                <FormControl
                  style={{ width: 240, fontSize: 20, color: '#00599d' }}
                  type="text"
                  maxLength={30}
                  value={scIdNo}
                  placeholder=""
                  onChange={(e) => {
                    setScIdNo(e.target.value)
                    setErrorMsg("")
                  }}
                />{" "}
              </Form>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{width: 300, height: 300, cursor: 'pointer', marginTop: 20}} onClick={handleClick}>
                <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660981863926.png' style={{width: 300, height: 300, resizeMode: 'contain'}} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{display: 'none'}} // Make the file input element invisible 
                />
              </div>
              <div onClick={() => onTumuloyPress()} style={{bottom: 0, cursor: 'pointer'}} >
                <img src={Done} style={{width: 150, height: 75, resizeMode: 'contain'}} />
              </div>
            </div>
          </div>
        </div>
        <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', minWidth: 400}}>
          <InnerPageHeader hasBackBtn goBackClick={() => setLevel(0)} title="" style={{position: 'absolute'}}/>
        </div>
      </div>
    }
    { level == 2 && 
      <div className={classes.loginContainer}>
        <div className={classes.loginContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660982943166.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
          <p style={{ marginLeft: 20, width: 100, fontSize: 20, marginTop: 10 }}>Medicine:</p>
          <div style={{width: 200}}>
            <Dropdown options={medOptions} onChange={_onSelect} value={defaultMedOption} placeholder="Select an option" className='dropdown'/>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
          <p style={{ marginLeft: 20, width: 100, fontSize: 20, marginTop: 10 }}>Dosage:</p>
          <div style={{width: 200}}>
            <Dropdown options={dosOptions} onChange={_onSelect} value={defaultDosOption} placeholder="Select an option" className='dropdown'/>
          </div>
        </div>
        <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'center', width: '33%'}}>
          { tabArr.map((item,index) => {
            return <div key={item} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '33%'}} onClick={() => tabAction(index)}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderWidth: 2, border: '3px solid #000000', borderRadius: 3, marginRight: 5}}>
                {((timeId == 1 && item.isSelect) || (timeId == 2 && item.isSelect) || (timeId == 3 && item.isSelect)) && <img src={CheckBoxCheck} style={{width: 20, height: 20}}/>}
              </div>
              <p style={{fontSize: 16}}>{item.tabName}</p>
            </div>
          }) }
        </div>
        <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', minWidth: 400}}>
          <InnerPageHeader hasBackBtn goBackClick={() => setLevel(1)} title="" style={{position: 'absolute'}}/>
        </div>
      </div> 
    }
    </>
  );
};

export default LoginPage;
