import * as React from "react";
import Green from '../../assets/images/common-images/greenbtnPlain.png';
import Gray from '../../assets/images/common-images/graybtnPlain.png';

const MyButton = (props) => {
  const styles = {
    buttonCounter: {
      position: 'absolute',
      marginLeft: '27%',
      marginBottom: '5%',
      background: 'red',
      color: 'white',
      borderRadius: 30,
      minWidth: 21,
      height: 21,
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: 1.5,
      fontWeight: '800'
    },
  };

  return (
    <div 
      style={{display: 'flex', backgroundColor: 'transparent', alignItems: 'center', 
      justifyContent: 'center', cursor: 'pointer', width: 150, height: 75}}
    >
      <img src={props.isDisabled ? Gray : Green} style={{width: 150, height: 75, resizeMode: 'contain'}}/>
      <p style={{fontSize: 15, fontWeight: '700', color: 'white', position: 'absolute', marginTop: 12}}>{props.title.toUpperCase()}</p>
      {!props.isDisabled && props.hasCounter && <p style={styles.buttonCounter}>2</p>}
    </div> 
  );
};

export default MyButton;
