import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  homeContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    margin: '0 auto',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  homeContentLandscape: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    transform: "rotate(270deg)",
  },
  sliderIndicatorContainer: {
    position: "absolute",
    top: "50%",
    left: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sliderIndicator: {
    border: "2px solid white",
    width: 7,
    height: 7,
    border: "1px solid white",
    borderRadius: "50%",
    marginBottom: 8,
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  description: {
    color: "#ffffff",
    fontSize: 12,
    margin: 0,
    maxWidth: 150,
    minWidth: 170,
    backgroundColor: "transparent",
    paddingRight: 10,
  },
  lastmoddate: {
    color: "#bbbbbb",
    fontSize: 10,
    marginTop: 5,
    maxWidth: 150,
    minWidth: 170,
    backgroundColor: "transparent",
    paddingRight: 10,
  },
  historyContainer: {
    height: 120,
    width: 120,
    marginLeft: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 180,
    height: 180,
    marginLeft: 15,
    marginRight: 15,
    objectFit: "contain",
    backgroundColor: 'transparent'
  },
  itemLeftContainer: {
    width: 200,
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: '1px #00599d',
    borderRightStyle: 'solid',
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    backgroundColor: 'transparent',
    cursor: 'pointer'
  },
  itemRightContainer: {
    width: 200,
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: '1px #00599d',
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    backgroundColor: 'transparent',
    cursor: 'pointer'
  },
  // titleContainer: {
  //   minWidth: 380,
  //   maxWidth: 380,
  //   height: 20,
  //   marginTop: 45,
  //   marginBottom: 45,
  //   alignItems: "center",
  //   display: "flex",
  //   flexDirection: "column",
  // },
  // title: {
  //   color: "white",
  //   fontFamily: "canaroMedium",
  //   fontSize: 16,
  //   maxWidth: 380,
  //   minWidth: 380,
  //   textAlign: "left",
  //   marginTop: 20,
  //   marginLeft: 40,
  // },
  titleContainer: {
    minWidth: 480,
    maxWidth: 480,
    height: 25,
    backgroundColor: "#557daa",
    marginTop: 45,
  },
  title: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 16,
    position: "absolute",
    right: 10,
  },
  name: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 14,
    position: "absolute",
    left: 10,
    textAlign: 'left'
  },
  logo: {
    width: 80,
    height: 80,
    borderRadius: 50,
    border: "2px solid #00acb8",
    marginTop: 20,
  },
  prodName: {
    color: "black",
    fontSize: 16,
    backgroundColor: "transparent",
  },
  amount: {
    color: "#00599d",
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'right',
    backgroundColor: "transparent",
    zIndex: 1,
    width: 180,
    marginTop: 10
  },
  soldout: {
    width: 60, 
    height: 60,
    objectFit: 'cover',
  },
  soldoutContainer: {
    width: 60, 
    height: 60,
    position: 'absolute',
    top: 0, left: 0
  },
  balanceContainer: {
    zIndex: 2,
    position: 'absolute',
    top: 10, left: 50
  }
}));
