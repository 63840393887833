import Boost from '../assets/images/footer-images/boost.png';
import Broadcast from '../assets/images/footer-images/broadcast.png';
import Store from '../assets/images/footer-images/store.png';
import Video from '../assets/images/footer-images/video.png';
import Points from '../assets/images/footer-images/points.png';

export const sideFooterMenus = [
  {
    label: 'Store',
    icon: Store
  },
  {
    label: 'Points',
    icon: Points
  },
  // {
  //   label: 'Videos',
  //   icon: Video
  // },
];
