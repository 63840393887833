import Phone from '../assets/images/footer-images/phoneCall.png';
import Messenger from '../assets/images/common-images/messenger.png';
import Prescriptions from '../assets/images/footer-images/prescriptions.png';
import Cart from '../assets/images/footer-images/cart.png';
import Care from '../assets/images/footer-images/care.png';
import Videos from '../assets/images/footer-images/videos.png';
import Products from '../assets/images/footer-images/products.png';

export const footerMenus = [
  {
    label: 'Prescriptions',
    icon: Prescriptions
  },
  // {
  //   label: 'Products',
  //   icon: Products
  // },
  {
    label: 'Care',
    icon: Care
  },
  // {
  //   label: 'Videos',
  //   icon: Videos
  // },
  {
    label: 'Cart',
    icon: Cart
  },
];
