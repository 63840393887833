import React, { useEffect, useState } from 'react';
import ProfileIcon from '../ProfileIcon';
import ScoreCounter from '../ScoreCounter';
import useStyles from './styles';
import Notif from '../../assets/images/common-images/notifications.png';
import Store from '../../assets/images/common-images/store.png';
import Cart from '../../assets/images/header-images/cart.png';
import Rider from '../../assets/images/header-images/rider.png';

import {
  getUserBalance
} from "../../services/ApiService";

const Header = (props) => {
  const classes = useStyles();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    fetchGetBalance();
  }, [])

  const fetchGetBalance = async () => {
    try {
      const response = await getUserBalance();

      if (response) {
        // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), process.env.REACT_APP_ENCRYPT_KEY).toString();
        localStorage.setItem(
          "balance",
          JSON.stringify(response.data.data)
        );
        setBalance(response.data.data.coinAmount);
        // setTickets(response.data.data.ticketQuantity);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.profileContainer}>
        <ProfileIcon setSidebarOpen={props.setSidebarOpen} avatar={props.avatar} notificationCount={props.notificationCount}/>
      </div>
      {/* <div className={classes.balanceContainer}>
        <ScoreCounter balance={balance}/>
      </div> */}
      {/* <div className={classes.buyContainer} onClick={() => props.onBuyClick()}>
        <p className={classes.buy}>LEARN</p>
      </div> */}
      {/* <div className={classes.storeContainer} onClick={() => props.onStoreClick()}>
        <img src={Store} className={classes.store} />
      </div> */}
      <div className={classes.storeContainer} >
        <p className={classes.rxTipid}>RxTipid</p>
      </div>
      {/* <div className={classes.notifContainer} onClick={() => props.onNotifClick()}>
        <img src={Notif} className={classes.notif} />
      </div> */}
      {/* <div className={classes.notifContainer} onClick={() => props.onCartClick()}>
        <img src={Cart} className={classes.notif} />
      </div> */}
      <div className={classes.notifContainer} onClick={() => props.onRiderClick()}>
        <img src={Rider} className={classes.notif} />
        <div className={classes.riderCounter}>1</div>
      </div>
    </div>
  );
};

export default Header;
