import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  homeContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    margin: '0 auto',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  homeContentLandscape: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    transform: "rotate(270deg)",
  },
  sliderIndicatorContainer: {
    position: "absolute",
    top: "50%",
    left: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sliderIndicator: {
    border: "2px solid white",
    width: 7,
    height: 7,
    border: "1px solid white",
    borderRadius: "50%",
    marginBottom: 8,
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  description: {
    color: "#ffffff",
    fontSize: 12,
    margin: 0,
    maxWidth: 150,
    minWidth: 170,
    backgroundColor: "transparent",
    paddingRight: 10,
  },
  lastmoddate: {
    color: "#bbbbbb",
    fontSize: 10,
    marginTop: 5,
    maxWidth: 150,
    minWidth: 170,
    backgroundColor: "transparent",
    paddingRight: 10,
  },
  historyContainer: {
    height: 120,
    width: 120,
    marginLeft: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 350,
    height: 350,
    marginLeft: 15,
    marginRight: 15,
    objectFit: "contain",
    backgroundColor: 'transparent'
  },
  title: {
    color: "#404040",
    fontFamily: "canaroMedium",
    fontSize: 14,
    fontWeight: '300',
  },
  value: {
    color: "black",
    fontFamily: "canaroMedium",
    fontSize: 18,
    fontWeight: '500',
  },
  name: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 14,
    position: "absolute",
    left: 10,
    textAlign: 'left'
  },
  itemContainer: {
    // height: 450,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: '1px #00599d',
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    backgroundColor: 'transparent'
  },
  prodName: {
    color: "black",
    fontWeight: '500',
    fontSize: 18,
    backgroundColor: "transparent",
  },
  amount: {
    color: "#00599d",
    fontSize: 42,
    fontWeight: '500',
    textAlign: 'center',
    backgroundColor: "transparent",
    zIndex: 1,
    width: 400,
  },
  
  balanceContainer: {
    zIndex: 2,
    position: 'absolute',
    top: 10, left: 50
  },
  buy: {
    width: 150,
    height: 75,
    objectFit: 'cover',
    cursor: 'pointer',
    marginBottom: 20
  },
  detailsContainer: {
    // height: 450,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent'
  },
  bearer: {
    color: "black",
    fontSize: 16,
    backgroundColor: "transparent",
    marginTop: 10
  },
  please: {
    color: "#404040",
    fontSize: 14,
    marginTop: 20
  }
}));
