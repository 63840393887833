import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {formatNumberWithComma, getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Uminom_1.png';
import checkButton from '../../../assets/images/common-images/checkIcon.png';
import innerYes from '../../../assets/images/common-images/oo_inom.png';
import Done from '../../../assets/images/common-images/done.png'
import Plus from '../../../assets/images/common-images/plusIcon.png'
import Minus from '../../../assets/images/common-images/minusIcon.png'
import {
  Form,
  FormControl
} from "react-bootstrap";
import moment from 'moment';

import {
  getReseta
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import MyButton from '../../../Components/Common/MyButton';
import Popup from '../../../Components/Popup';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Reorder = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [sku, setSku] = useState([]);
  const [ds, setDs] = useState([]);
  const [dsData, setDsData] = useState([]);
  const [result, setResult] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [level, setLevel] = useState(0);
  const [selectedDs, setSelectedDs] = useState();
  const [search, setSearch] = useState("");
  const [previous, setPrevious] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const props = useLocation().state;

  const fetchData = async () => {
    try {
      // setLoader(true);
      // const response = await getReseta();
      // // console.log(`response2=${JSON.stringify(response)}`)
      // if (response) {
      //   setSku(response.data.data.map(item => {
      //     return {
      //       ...item,
      //       isSelected: true
      //     }
      //   }));       
      //   setIsLoaded(true);
      //   setLoader(false);
      // }
      setResult([
        {
          id: 3,
          generic: {
            id: 3,
            name: 'Metformin',
          },
          brand: {
            id: 3,
            name: 'TGP',
            price: 3,
          },
          dosage: {
            id: 3,
            name: '500mg'
          },
        }
      ])
      setPrevious(
        [
          {
            id: 1,
            create_date: "2022-08-21T05:03:27.000Z",
            details: [
              {
                id: 1,
                generic: {
                  id: 1,
                  name: 'Losartan'
                },
                brand: {
                  id: 1,
                  name: 'TGP',
                  price: 5
                },
                dosage: {
                  id: 1,
                  name: '50mg'
                },
                quantity: 10,
                isSelected: true
              },
              {
                id: 2,
                generic: {
                  id: 2,
                  name: 'Atorvastatin'
                },
                brand: {
                  id: 2,
                  name: 'TGP',
                  price: 12
                },
                dosage: {
                  id: 2,
                  name: '20mg'
                },
                quantity: 10,
                isSelected: true
              },
            ]
          },
          {
            id: 2,
            create_date: "2022-08-14T05:03:27.000Z",
            details: [
              {
                id: 1,
                generic: {
                  id: 1,
                  name: 'Losartan'
                },
                brand: {
                  id: 1,
                  name: 'TGP',
                  price: 5
                },
                dosage: {
                  id: 1,
                  name: '50mg'
                },
                quantity: 14,
                isSelected: true
              },
              {
                id: 2,
                generic: {
                  id: 2,
                  name: 'Atorvastatin'
                },
                brand: {
                  id: 2,
                  name: 'TGP',
                  price: 12
                },
                dosage: {
                  id: 2,
                  name: '20mg'
                },
                quantity: 14,
                isSelected: true
              },
            ]
          }
        ]
      )
      setDsData([
        {
          id: 100,
          name: "Watsons",
          location: {
            id: 1,
            name: 'Berkely Square'
          },
          details: [
            {
              id: 1,
              generic: {
                id: 1,
                name: 'Losartan'
              },
              brand: {
                price: 5,
              },
              dosage: {
                id: 1,
                name: '50mg'
              },
            },
            {
              id: 2,
              generic: {
                id: 2,
                name: 'Atorvastatin'
              },
              brand: {
                price: 8.333333,
              },
              dosage: {
                id: 2,
                name: '20mg'
              },           
            },
            {
              id: 3,
              generic: {
                id: 3,
                name: 'Metformin',
              },
              brand: {
                price: 3,
              },
              dosage: {
                id: 3,
                name: '500mg'
              },
            }
          ]
        },
        {
          id: 101,
          name: 'Mercury',
          location: {
            id: 2,
            name: "Ever Commonwealth"
          },
          details: [
            {
              id: 1,
              generic: {
                id: 1,
                name: 'Losartan'
              },
              brand: {
                price: 5.25
              },
              dosage: {
                id: 1,
                name: '50mg'
              },
            },
            {
              id: 2,
              generic: {
                id: 2,
                name: 'Atorvastatin'
              },
              brand: {
                price: 9.5
              },
              dosage: {
                id: 2,
                name: '20mg'
              },
            },
            {
              id: 3,
              generic: {
                id: 3,
                name: 'Metformin',
              },
              brand: {
                price: 3.5,
              },
              dosage: {
                id: 3,
                name: '500mg'
              },
            }
          ]
        }
      ])
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onDonePress = async () => {
    // const selected = data.filter(item => item.isSelected);
    // const param = selected.map((item) => {
    //   return {
    //     boughtDetailsId: item.id,
    //     umaga: timeId == 1 ? item.umaga : 0,
    //     tanghali: timeId == 2 ? item.tanghali : 0,
    //     gabi: timeId == 3 ? item.gabi : 0,
    //   }
    // })

    // await uploadTaken(param)
    
    if (props.onBackPress) {
      props.onBackPress();
    }
    navigate(-1);
  }

  const onRowPress = (rowData) => {
    console.log(`onRowPress=${JSON.stringify(rowData)}`)
    setSelectedData(rowData);
    setLevel(1);
  }

  const addQty = (e, rowData) => {
    e.stopPropagation();
    let tempGamot = [...selectedData.details
      .map(item => {
        if (item.id == rowData.id && item.quantity < 30) {
          return {
            ...item,
            quantity: item.quantity + 1
          }
        }
        else {
          return item
        }
      })];
      setSelectedData({
        ...selectedData,
        details: tempGamot
      });
  }

  const minusQty = (e, rowData) => {
    e.stopPropagation();
    let tempGamot = [...selectedData.details
      .map(item => {
        if (item.id == rowData.id && item.quantity > 0) {
          return {
            ...item,
            quantity: item.quantity - 1
          }
        }
        else {
          return item
        }
      })];
      setSelectedData({
        ...selectedData,
        details: tempGamot
      });
  }

  const addQtyDs = (e, rowData) => {
    e.stopPropagation();
    let tempGamot = [...ds
    .map(store => {
      let total = 0;
      const details = store.details.map(item => {
        if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity < 30) {
          total += item.brand.price * (item.quantity + 1);
          return {
            ...item,
            quantity: item.quantity + 1
          };
        }
        else {
          total += item.brand.price * item.quantity;
          return item
        }
      })
      return {
        ...store,
        details: details,
        total: total
      }
    })];
    const selected = tempGamot.filter(item => item.id == selectedDs.id)
    if (selected.length > 0) {
      setSelectedDs(selected[0]);
    }
    setDs(tempGamot);
  }

  const minusQtyDs = (e, rowData) => {
    e.stopPropagation();
    let tempGamot = [...ds
    .map(store => {
      let total = 0;
      const details = store.details.map(item => {
        if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity > 0) {
          total += item.brand.price * (item.quantity - 1);
          return {
            ...item,
            quantity: item.quantity - 1
          }
        }
        else {
          total += item.brand.price * item.quantity;
          return item
        }
      })
      return {
        ...store,
        details: details,
        total: total
      }
    })];
    const selected = tempGamot.filter(item => item.id == selectedDs.id)
    if (selected.length > 0) {
      setSelectedDs(selected[0]);
    }
    setDs(tempGamot);
  }

  const onDsRowPress = (rowData) => {
    setSelectedDs(rowData);
    setLevel(4);
  }

  const onAddRowPress = (rowData) => {
    if (selectedData.details.filter(item => item.generic.id == rowData.generic.id).length > 0) {
      // do nothing existing
    }
    else {
      selectedData.details.push({
        ...rowData,
        quantity: 10
      })
    }
    setLevel(1);
    setSearch("");
  }

  const onReorderPress = () => {
    let data = [];
    dsData.map(store => {
      let total = 0;
      let details = [];
      sku.filter(item => item.isSelected).map(selected => {
        const storeItem = store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id)
        if (storeItem) {
          total += storeItem.brand.price * selected.quantity;
          details.push({
            ...storeItem,
            quantity: selected.quantity
          })
        }
      })
      data.push({
        ...store,
        total,
        details: [...details]
      })
    })
    setDs(data);
    setLevel(3);


    // ds.gamot.filter(item => item.isSelected).map(selected => {
    //   const brand = this.state.skus.filter(item => item.brand.discountId == this.state.selectedDiscountId)
    //     .find(item => item.generic.id == selected.generic.id)
    //   data.push({
    //     resetaDetailsId: selected.id,
    //     genericsId: selected.generic.id,
    //     brandedId: brand.id,
    //     dosageId: selected.dosage.id,
    //     discountId: this.state.selectedDiscountId,
    //     umaga: selected.umaga,
    //     tanghali: selected.tanghali,
    //     gabi: selected.gabi,
    //     remarks: selected.remarks,
    //     locationId: selected.locationId
    //   })
    // })
  }

  const w = 400;
  const h = 200;

  return (
    <div className={classes.reorderContainer}>
      {loader && <Loader />}
      {level == 0 &&
        <div className={classes.reorderContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661500732094.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            { previous.map(sku => 
              <div key={sku.id} style={{marginTop: 10, marginLeft: 20, width: '90%', minHeight: 380, backgroundColor: '#B0E0E6', borderRadius: 20, cursor: 'pointer'}}
                onClick={() => onRowPress(sku)}
              >
                <p style={{display: "flex", flexDirection: 'column', fontWeight: '600', justifyContent: 'center', width: w * 0.9, paddingTop: 15, color: 'black', fontSize: 24, textAlign: 'center'}} >{moment(sku.create_date).format("MMMM DD, YYYY")}</p>
                {sku.details.map((rowData,index) => {
                  return <div key={rowData.id} style={{display: "flex", width: w, paddingTop: 20, height: 80, flexDirection: 'column', justifyContent: 'center', 
                    alignItems: 'flex-start'}}>
                    
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.85, height: 30, marginBottom: 5}}>
                      <p style={{marginLeft: 15, color: '#00599d', fontSize: 20, width: '65%', textAlign: 'left'}} >{rowData.generic.name} {rowData.dosage.name}</p>
                      <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '35%', marginLeft: 5, color: '#00599d', textAlign: 'right'}}>₱{formatNumberWithComma(rowData.brand.price * rowData.quantity, 2)}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.85, height: 30, marginBottom: 5}}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '60%'}}>
                        <p style={{color: '#00599d', fontSize: 18, textAlign: 'left', marginTop: 5, marginLeft: 15}} >Brand:</p>
                        <p style={{marginLeft: 5, color: 'black', fontSize: 20, textAlign: 'left', marginTop: 5}} >{rowData.brand.name}</p>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '40%', alignItems: 'center', height: 30, marginTop: 0}}>
                        <p style={{color: '#00599d', fontSize: 18, textAlign: 'left', marginTop: 5, marginRight: 5}} >Qty:</p>
                        <p style={{height: 18, fontSize: 20, height: 25}}>{rowData.quantity}</p>
                      {/*   <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '40%', marginTop: 0, marginLeft: 15}}>
                          <div style ={{cursor: 'pointer', width: 25, height: 30, zIndex: 1}} onClick={(e) => addQty(e, rowData)}>
                            <img src={Plus} style={{widht: 20, height: 20, marginRight: 5}}/>
                          </div> 
                          <p style={{height: 18, fontSize: 20, height: 25}}>{rowData.quantity}</p>
                          <div style ={{cursor: 'pointer', width: 25, height: 30, zIndex: 1}} onClick={(e) => minusQty(e, rowData)}>
                            <img src={Minus} style={{widht: 20, height: 20, marginLeft: 5}} />
                          </div>
                        </div>*/}
                      </div> 
                    </div>
                  </div>
                })}
                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#00599d', fontSize: 28, height: 15, marginTop: 20}} >Total Price:</p>
                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 50, height: 20}} >₱{formatNumberWithComma(sku.details.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
                <p style={{display: "flex", flexDirection: 'column', justifyContent: 'center', width: w * 0.9, marginTop: 50, color: 'black', fontSize: 16, textAlign: 'center'}} >Tap to reorder</p>
              </div>
            )}
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 0, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            { sku.length > 0 &&
              sku.filter(item => item.isSelected).length > 0 &&
              <div onClick={() => onReorderPress()} style={{cursor: 'pointer'}} >
                <MyButton title="ReOrder"/>
              </div>
            }
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}
              hasBackBtn
            />
          </div>
        </div>
      }
      {level == 1 &&
        <div className={classes.reorderContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661309091920.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 20, width: '90%', minHeight: 280 + selectedData.details.length * 30, backgroundColor: '#B0E0E6', borderRadius: 20}}>
              {selectedData.details.map((rowData,index) => {
                return <div key={rowData.id} style={{display: "flex", width: w, paddingTop: 20, height: 80, flexDirection: 'column', justifyContent: 'center', 
                  alignItems: 'flex-start', cursor: 'pointer'}} onClick={() => onRowPress(rowData)}>
                  
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.85, height: 30, marginBottom: 5}}>
                    <p style={{marginLeft: 15, color: '#00599d', fontSize: 20, width: '65%', textAlign: 'left'}} >{rowData.generic.name} {rowData.dosage.name}</p>
                    <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '35%', marginLeft: 5, color: '#00599d', textAlign: 'right'}}>₱{formatNumberWithComma(rowData.brand.price * rowData.quantity, 2)}</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.85, height: 30, marginBottom: 5}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '60%'}}>
                      <p style={{color: '#00599d', fontSize: 18, textAlign: 'left', marginTop: 5, marginLeft: 15}} >Brand:</p>
                      <p style={{marginLeft: 5, color: 'black', fontSize: 20, textAlign: 'left', marginTop: 5}} >{rowData.brand.name}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '40%', height: 30, marginTop: 0, marginLeft: 40}}>
                      <p style={{color: '#00599d', fontSize: 18, textAlign: 'left', marginTop: 5}} >Qty:</p>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '40%', marginTop: 0, marginLeft: 15}}>
                        <div style ={{cursor: 'pointer', width: 25, height: 30, zIndex: 1}} onClick={(e) => addQty(e, rowData)}>
                          <img src={Plus} style={{widht: 20, height: 20, marginRight: 5}}/>
                        </div>
                        <p style={{height: 18, fontSize: 20, height: 25}}>{rowData.quantity}</p>
                        <div style ={{cursor: 'pointer', width: 25, height: 30, zIndex: 1}} onClick={(e) => minusQty(e, rowData)}>
                          <img src={Minus} style={{widht: 20, height: 20, marginLeft: 5}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })}
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#00599d', fontSize: 28, height: 15, marginTop: 20}} >Total Price:</p>
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 50, height: 20}} >₱{formatNumberWithComma(selectedData.details.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
            </div>
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div onClick={() => navigate(routes.home)} style={{cursor: 'pointer'}} >
              <MyButton title='Confirm'/>
            </div>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => setLevel(0)} title="" style={{position: 'absolute'}} 
              hasNewBtn
              onNewPress={() => setLevel(10)}
            />
          </div>
        </div>
      }
      {level == 10 && <div className={classes.reorderContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661241916145.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <Form inline style={{marginTop: 20, marginLeft: 50}}>
              <FormControl
                style={{ width: '80%', fontSize: 20, height: 40, borderRadius: 20, left: '10%' }}
                type="text"
                maxLength={20}
                value={search}
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />{" "}
            </Form>
            <div style={{marginTop: 20, marginLeft: 40, minHeight: 200}}>
              
              {result.filter(item => item.generic.name.toLowerCase().includes(search.toLowerCase()) && search.trim() != "").map((rowData,index) => {
                return <div key={rowData.id} style={{display: "flex", width: w * 0.8, height: h * 0.5, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                  <div
                    style={{cursor: 'pointer', display: 'flex', width: w * 0.8, height: h * 0.3, position: 'absolute', backgroundColor: '#B0E0E6', borderRadius: 20, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}
                    onClick={() => onAddRowPress(rowData)} 
                  >
                    <p style={{textAlign: 'center', fontWeight: '500', color: '#00599d', fontSize: 18, marginTop: 10}} >{rowData.generic.name} {rowData.dosage.name}</p>
                    <p style={{marginLeft: 5, textAlign: 'center', color: 'black', fontSize: 18, marginTop: 10}}>(₱{formatNumberWithComma(rowData.brand.price, 2)})</p>
                  </div>
                </div>
              })}
            </div>
            </>
          </div>
          <InnerPageHeader hasBackBtn goBackClick={() => setLevel(2)} title="" style={{position: 'absolute'}}
          />
        </div>
      }
    </div>
  );
};

export default Reorder;