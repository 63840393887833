import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Phonecall_1.png';
import Uminom from '../../../assets/images/footer-images/footer_uminom_selected.png';
import Sumagot from '../../../assets/images/footer-images/footer_sumagot_selected.png';
import Chart from '../../../assets/images/footer-images/footer_chart_selected.png';

import {
  getQuestionnaire, uploadAnswer
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isMobile } from 'react-device-detect';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Care = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [level, setLevel] = useState(0);
  const [selectedData, setSelectedData] = useState();
  const props = useLocation().state;

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getQuestionnaire();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data.data.map((item) => {
          return {
            ...item,
            line1: {
              text: `${item.question}`,
              color: '#00599d',
              fontSize: 18
            },
            isSelected: false,
            answerId: item.answerId
          }}
        ));
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    // fetchData();
    setData([
      {
        id: 1,
        image: Uminom,
        name: `Dosage`,
        number: `+639565797816`
      },
      {
        id: 2,
        image: Sumagot,
        name: `Side Effects`,
        number: `0281234567`
      },
      {
        id: 3,
        image: Chart,
        name: `Med Chart`,
        number: `+639565797817`
      }
    ])
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onRowClick = (index: number) => {
    switch(index) {
      case 1: 
        navigate(routes.uminom, {state: { userData: props.userData, dashData: props.dashData}});
        break;
      case 2: 
        navigate(routes.sumagot, {state: { userData: props.userData, dashData: props.dashData}});
        break;
      case 3: 
        navigate(routes.chart, {state: { userData: props.userData, dashData: props.dashData}});
        break;
      default:
    }
  }

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      {level == 0 &&
        <div className={classes.homeContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661326834845.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 30, marginLeft: 60, height: 480, cursor: 'pointer'}}>
              {data.map((rowData,index) => {
                const w = 300;
                const h = 120;
                return <div
                  key={rowData.id}
                  style={{width: w * 0.9, height: h, justifyContent: 'center', alignItems: 'center'}}
                  onClick={() => {
                    onRowClick(rowData.id)
                  }}
                >
                  <div style={{display: "flex", width: w * 0.9, height: h * 0.8, backgroundColor: '#B0E0E6', borderRadius: h * 0.4, alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                    <img
                      style={{width: 84, height: 84, marginLeft: 10}}
                      src={rowData.image}
                    />
                    <div style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', display: 'flex'}}>
                      <p style={{marginTop: 10, textAlign: 'center', fontWeight: '500', width: w * 0.50, color: '#00599d', fontSize: 20, marginRight: 15}}>{rowData.name}</p>
                    </div>
                  </div>          
                </div>
              })}
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
    </div>
  );
};

export default Care;
