import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Phonecall_1.png';
import footerPhone from '../../../assets/images/common-images/phoneCall.png';
import Tawagan from '../../../assets/images/common-images/tawagan.png'
import Phone from '../../../assets/images/common-images/phoneCall.png';
import Messenger from '../../../assets/images/common-images/messenger.png';
import Viber from '../../../assets/images/common-images/viber.png';

import {
  getQuestionnaire, uploadAnswer
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isMobile } from 'react-device-detect';
import MyButton from '../../../Components/Common/MyButton';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Tumawag = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [level, setLevel] = useState(0);
  const [selectedData, setSelectedData] = useState();
  const props = useLocation().state;

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getQuestionnaire();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data.data.map((item) => {
          return {
            ...item,
            line1: {
              text: `${item.question}`,
              color: '#00599d',
              fontSize: 18
            },
            isSelected: false,
            answerId: item.answerId
          }}
        ));
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    // fetchData();
    setData([
      {
        id: 1,
        image: "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660895428436.png",
        name: `Maria Dela Cruz`,
        position: 'Pharmacist',
        cellphone: `09565797816`,
        landline: '0281234567',
        messenger: `https://m.me/j/AbZ4dOLYgRS5gcwV`
      },
      {
        id: 5,
        image: "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660895428436.png",
        name: `Juana Del Rosario`,
        position: 'Pharmacist',
        cellphone: `09565797817`,
        landline: '0281234568',
        messenger: `https://m.me/j/AbZ4dOLYgRS5gcwV`
      },
      {
        id: 2,
        image: "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659077941007.png",
        name: `Pablo Cruz`,
        position: 'Team Leader',
        cellphone: `09565797818`,
        messenger: `https://m.me/j/AbZ4dOLYgRS5gcwV`
      },
      {
        id: 3,
        image: "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660895535170.png",
        name: `Peter Santos`,
        position: 'RxTipid Rider',
        cellphone: `09565797818`,
        messenger: `https://m.me/j/AbZ4dOLYgRS5gcwV`
      }
    ])
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const getContent = (rowData) => {
    const w = 300;
    const h = 500;

    return <div key={rowData.id} style={{textDecoration: 'none', flexDirection: 'column', width: 300, height: 470, justifyContent: 'center', alignItems: 'center'}} 
    >
      <div
        style={{width: w * 0.9, height: h * 0.9, backgroundColor: '#B0E0E6', borderRadius: 6, justifyContent: 'center', alignItems: 'center', marginTop: 30}}
      >
        <div style={{display: "flex", width: w * 0.9, height: h * 0.9, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <img
            style={{width: 200, height: 200}}
            src={rowData.image}
          />
          <div style={{flexDirection: 'column', alignItems: 'flex-start'}}>
            {isMobile && 
              // <a style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: w * 0.9}} href={rowData.messenger}>
              //   <p style={{marginTop: 20, textAlign: 'center', fontWeight: '500', color: '#00599d', fontSize: 20}}>{rowData.name}</p>
              //   <img src={Messenger} style={{width: 40, height: 40, marginLeft: 5}} />
              // </a>
              <p style={{marginTop: 0, textAlign: 'center', fontWeight: '500', color: '#00599d', fontSize: 20}}>{rowData.name}</p>
            }
            {isMobile && 
              <a style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: w * 0.9}} href={`tel:${rowData.cellphone}`}>
                <p style={{marginTop: 20, marginLeft: 5, textAlign: 'center', fontWeight: '500', color: 'black', fontSize: 16, fontStyle: 'italic'}}>{rowData.cellphone}</p>
                <img src={Phone} style={{width: 40, height: 40, marginLeft: 5}} />
              </a>
            }
            {isMobile && rowData.landline && <a style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: w * 0.9, backgroundColor: 'transparent'}} href={`tel:${rowData.landline}`} >
                <p style={{marginTop: 20, textAlign: 'center', fontWeight: '500', color: 'black', fontSize: 16, fontStyle: 'italic', marginLeft: 5}}>{rowData.landline}</p>
                <img src={Phone} style={{width: 40, height: 40, marginLeft: 5}} />
              </a>
            }
            {isMobile && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                <a href={rowData.messenger}>
                  <img src={Messenger} style={{width: 40, height: 40, marginRight: 25}}/>
                </a>
                <a href={`viber://chat?number=${encodeURIComponent(rowData.cellphone.replace(/[^0-9+]/g, ''))}`}>
                  <img src={Viber} style={{width: 40, height: 40, marginLeft: 25}} />
                </a>
              </div>
            }
            {!isMobile && 
              // <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: w * 0.9}} onClick={()=> window.open(rowData.messenger, "_blank")}>
              //   <p style={{marginTop: 20, textAlign: 'center', fontWeight: '500', color: '#00599d', fontSize: 20}}>{rowData.name}</p>
              //   <img src={Messenger} style={{width: 40, height: 40, marginLeft: 5}} />
              // </div>
              <p style={{marginTop: 0, textAlign: 'center', fontWeight: '500', color: '#00599d', fontSize: 20}}>{rowData.name}</p>
            }
            {!isMobile && 
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: w * 0.9}} onClick={()=> window.open(`tel:${rowData.cellphone}`, "_blank")} >
                <p style={{marginTop: 20, marginLeft: 5, textAlign: 'center', fontWeight: '500', color: 'black', fontSize: 16, fontStyle: 'italic'}}>{rowData.cellphone}</p>
                <img src={Phone} style={{width: 40, height: 40, marginLeft: 5}} />
              </div>
            }
            {!isMobile && rowData.landline && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: w * 0.9}} onClick={()=> window.open(`tel:${rowData.landline}`, "_blank")} >
                <p style={{marginTop: 20, marginLeft: 5, textAlign: 'center', fontWeight: '500', color: 'black', fontSize: 16, fontStyle: 'italic'}}>{rowData.landline}</p>
                <img src={Phone} style={{width: 40, height: 40, marginLeft: 5}} />
              </div>
            }
            {!isMobile && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                <img src={Messenger} style={{width: 40, height: 40, marginRight: 25}} onClick={()=> window.open(rowData.messenger, "_blank")}/>
                <img src={Viber} style={{width: 40, height: 40, marginLeft: 25}} onClick={()=> window.open(`viber://chat?number=${encodeURIComponent(rowData.cellphone.replace(/[^0-9+]/g, ''))}`, "_blank")} />
              </div>
            }
          </div>
        </div>         
      </div>
    </div>
  }

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      {level == 0 &&
        <div className={classes.homeContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661224338024.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 30, marginLeft: 50, height: 480, cursor: 'pointer'}}>
              {data.map((rowData,index) => {
                const w = 340;
                const h = 140;
                return <div
                  style={{width: w * 0.9, height: h, justifyContent: 'center', alignItems: 'center'}}
                  onClick={() => {
                    setSelectedData(rowData);
                    setLevel(1);
                  }}
                >
                  <div style={{display: "flex", width: w * 0.9, height: h * 0.8, backgroundColor: '#B0E0E6', borderRadius: 6, alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                    <img
                      style={{width: 84, height: 84}}
                      src={rowData.image}
                    />
                    <div style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', display: 'flex'}}>
                      <p style={{marginTop: 10, textAlign: 'center', fontWeight: '500', width: w * 0.60, color: '#00599d', fontSize: 18, height: 12}}>{rowData.name}</p>
                      <p style={{marginTop: 0, textAlign: 'center', width: w * 0.60, color: 'black', fontSize: 14}}>{rowData.position}</p>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'space-around', flexDirection: 'row', width: w * 0.60}}>
                        <p style={{textAlign: 'center', color: '#00599d', fontSize: 14}}>{rowData.cellphone}</p>  
                        {rowData.landline && <p style={{textAlign: 'center', marginLeft: 20, color: '#00599d', fontSize: 14}}>{rowData.landline}</p>}
                      </div>
                    </div>
                  </div>          
                </div>
              })}
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
      {level == 1 && 
        <div className={classes.homeContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661224547270.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 60, height: 480, cursor: 'pointer'}}>
              {getContent(selectedData)}
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => setLevel(0)} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
    </div>
  );
};

export default Tumawag;
