import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import {formatNumberWithComma, getPlaceHolder} from '../../../utils/utils';
import SavingsTag from '../../../assets/images/common-images/savingsTag.png';
import Plus from '../../../assets/images/common-images/plusIcon.png'
import Minus from '../../../assets/images/common-images/minusIcon.png'
import CheckBoxCheck from '../../../assets/images/common-images/checkBoxCheck.png';

import { ids, routes } from '../../../constants';
import MyButton from '../../../Components/Common/MyButton';
import Popup from '../../../Components/Popup';

import {
  getReseta
} from "../../../services/ApiService";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Savings = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sku, setSku] = useState([]);
  const [ds, setDs] = useState([]);
  const [dsData, setDsData] = useState([]);
  const [level, setLevel] = useState(0);
  const [selectedData, setSelectedData] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [quantity, setQuantity] = useState(30);
  const props = useLocation().state;
  const [tabArr, setTabArr] = useState([
    { tabId: 15, tabName: '15 days', isSelect: false },
    { tabId: 30, tabName: '30 days', isSelect: true },
    { tabId: 60, tabName: '60 days', isSelect: false },
  ])

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getReseta();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setSku(response.data.data.map(item => {
          return {
            ...item,
            isSelected: true
          }
        }));       
        setIsLoaded(true);
        setLoader(false);
      }
      
      // setSku([
      //   {
      //     id: 1,
      //     generic: {
      //       id: 1,
      //       name: 'Losartan',
      //       category: 'Hypertension'
      //     },
      //     brand: {
      //       id: 1,
      //       name: 'Cozaar',
      //       price: 20,
      //     },
      //     dosage: {
      //       id: 1,
      //       name: '50mg'
      //     },
      //     quantity: 10,
      //     isSelected: true
      //   },
      //   {
      //     id: 2,
      //     generic: {
      //       id: 2,
      //       name: 'Atorvastatin',
      //       category: 'High Cholesterol'
      //     },
      //     brand: {
      //       id: 2,
      //       name: 'Lipitor',
      //       price: 38,
      //     },
      //     dosage: {
      //       id: 2,
      //       name: '20mg'
      //     },
      //     quantity: 10,
      //     isSelected: true
      //   },
      // ])
      setDsData([
        {
          id: 100,
          name: "RiteMed",
          details: [
            {
              id: 1,
              generic: {
                id: 1,
                name: 'Losartan',
                category: 'Hypertension'
              },
              brand: {
                price: 10,
              },
              dosage: {
                id: 4,
                name: '50mg'
              },
            },
            {
              id: 2,
              generic: {
                id: 2,
                name: 'Atorvastatin',
                category: 'High Cholesterol'
              },
              brand: {
                price: 19,
              },
              dosage: {
                id: 5,
                name: '20mg'
              },           
            },
            {
              id: 3,
              generic: {
                id: 3,
                name: 'Metformin',
              },
              brand: {
                price: 3,
              },
              dosage: {
                id: 6,
                name: '500mg'
              },
            }
          ]
        },
        {
          id: 101,
          name: 'TGP',
          details: [
            {
              id: 1,
              generic: {
                id: 1,
                name: 'Losartan'
              },
              brand: {
                price: 5
              },
              dosage: {
                id: 4,
                name: '50mg'
              },
            },
            {
              id: 2,
              generic: {
                id: 2,
                name: 'Atorvastatin'
              },
              brand: {
                price: 12
              },
              dosage: {
                id: 5,
                name: '20mg'
              },
            },
            {
              id: 3,
              generic: {
                id: 3,
                name: 'Metformin',
              },
              brand: {
                price: 3.5,
              },
              dosage: {
                id: 6,
                name: '500mg'
              },
            }
          ]
        }
      ])
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onRowPress = (rowData) => {
    let tempGamot = [];
    dsData.map(store => {
      const details = store.details.filter(item => item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id)
      if (details.length > 0) {
        tempGamot.push({
          ...store,
          details: details
        })
      }
    })

    setDs(tempGamot);
    setSelectedData(rowData);
    setLevel(1);
  }

  const onOptionPress = (data) => {
    setSelectedOption(data);
    setLevel(2);
  }

  const addQty = (e) => {
    e.stopPropagation();
    if (quantity < 100) {
      tabAction(quantity + 1)
    }
  }

  const minusQty = (e) => {
    e.stopPropagation();
    if (quantity > 0) {
      tabAction(quantity - 1)
    }
  }

  const tabAction = (tabId) => {
    let tempData = [...tabArr
    .map(item => {
      if (item.tabId == tabId) {
        return {
          ...item,
          isSelect: true
        }
      }
      else {
        return {
          ...item,
          isSelect: false
        }
      }
    })];
    setTabArr([...tempData]);
    setQuantity(tabId);
  }

  const onOrderPress = () => {
    navigate(-1);
  }

  const w = 300;
  const h = 200;
  
  return (
    <div className={classes.savingsContainer}>
      {loader && <Loader />}
      {level == 0 &&
        <div className={classes.savingsContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1661493513431.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 50, minHeight: 200}}>
              {sku.map((rowData,index) => {
                return <div key={rowData.id} style={{display: "flex", width: w, height: h, flexDirection: 'column', justifyContent: 'center', 
                  alignItems: 'center', cursor: 'pointer'}} onClick={() => onRowPress(rowData)}>
                  <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.6, color: '#00599d', fontSize: 18, border: '1px solid #B0E0E6', marginBottom: -1}} >{rowData.generic.ailmentName}</p>
                  <div
                    style={{display: 'flex', width: w * 0.9, height: h * 0.7, backgroundColor: rowData.isSelected ? '#B0E0E6' : '#B0E0E670', borderRadius: 20, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
                  >
                    <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 24, height: 30}} >{rowData.generic.name} {rowData.dosage.name}</p>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30}}>
                      <p style={{color: 'black', fontWeight: '500', fontSize: 20}} >Price: </p>
                      <p style={{height: 18, fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#00599d'}}>₱{formatNumberWithComma(rowData.brand.price, 2)}/tab</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30}}>
                      <p style={{color: 'black', fontWeight: '500', fontSize: 20}} >Monthly: </p>
                      <p style={{height: 18, fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#00599d'}}>₱{formatNumberWithComma(rowData.brand.price * 30, 2)}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30}}>
                      <p style={{color: 'black', fontWeight: '500', fontSize: 20}} >Brand: </p>
                      <p style={{height: 18, fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#00599d'}}>{rowData.brand.name} {rowData.dosage.name}</p>
                    </div>
                  </div>
                </div>
              })}
            </div>
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: 'black', fontSize: 20}} >Tap to select a medicine</p>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}
              // hasCartBtn
              // onCartPress={() => navigate(routes.cart, { state: {userData: props.userData} })}
              hasBackBtn
              // hasAddBtn
              // onAddPress={() => {}}
            />
          </div>
        </div>
      }
      {level == 1 &&
        <div className={classes.savingsContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: '#cce8fe', top: 30, marginLeft: '5%', alignItems: 'center', 
              flexDirection: 'column', width: '90%', height: 175, borderRadius: 8 }}
            >
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 18, height: 12, marginTop: 5}} >You are taking:</p>
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 28, height: 32}} >{selectedData.generic.name} {selectedData.dosage.name}</p>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20}} >Price: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#00599d'}}>₱{formatNumberWithComma(selectedData.brand.price, 2)}/tab</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20}} >Brand: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#00599d'}}>{selectedData.brand.name} {selectedData.dosage.name}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20}} >Monthly: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#00599d'}}>₱{formatNumberWithComma(selectedData.brand.price * 30, 2)}</p>
              </div>
            </div>
            <div style={{marginTop: 10, marginLeft: 50, minHeight: 200}}>
              {ds.map((temp,index) => {
                const rowData = temp.details[0];
                return <div key={index} style={{cursor: 'pointer', display: "flex", width: w, height: h * 1.2, flexDirection: 'column', 
                  justifyContent: 'center', alignItems: 'center'}} onClick={() => onOptionPress(temp)}
                >
                  <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.45, color: '#00599d', fontSize: 18, border: '1px solid #B0E0E6', marginBottom: -1}} >Option {index+1}</p>
                  <div
                    style={{display: 'flex', width: w * 0.9, height: h * 0.9, backgroundColor: '#B0E0E6', borderRadius: 20, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} 
                  >
                    <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 28, height: 30}} >{temp.name}</p>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30}}>
                      <p style={{color: 'black', fontWeight: '500', fontSize: 20}} >Price: </p>
                      <p style={{height: 18, fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#00599d'}}>₱{formatNumberWithComma(rowData.brand.price, 2)}/tab</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30}}>
                      <p style={{color: 'black', fontWeight: '500', fontSize: 20}} >Monthly: </p>
                      <p style={{height: 18, fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#00599d'}}>₱{formatNumberWithComma(rowData.brand.price * 30, 2)}</p>
                    </div>
                    {selectedData.brand.price - rowData.brand.price > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30, marginTop: 5}}>
                        <p style={{color: 'red', fontWeight: '500', fontSize: 28}} >SAVE: ₱{formatNumberWithComma((selectedData.brand.price - rowData.brand.price) * 30, 2)}</p>
                      </div>
                    }
                  </div>
                  {selectedData.brand.price - rowData.brand.price > 0 && <div style={{position: 'absolute', marginBottom: '30%', marginLeft: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img src={SavingsTag} style={{width: 120, height: 120}}/>
                      <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <p style={{fontSize: 32, fontWeight: '600', color: 'white', textAlign: 'center', height: 24}}>{formatNumberWithComma((selectedData.brand.price - rowData.brand.price)/selectedData.brand.price * 100, 0)}%</p>
                        <p style={{fontSize: 16, color: 'white', textAlign: 'center', height: 12}}>Savings</p>
                      </div>
                    </div>
                  }
                </div>
              })}
            </div>
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: 'black', fontSize: 20}} >Tap to select an option</p>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader goBackClick={() => setLevel(0)} title="" style={{position: 'absolute'}}
              // hasCartBtn
              // onCartPress={() => navigate(routes.cart, { state: {userData: props.userData} })}
              hasBackBtn
            />
          </div>
        </div>
      }
      {level == 2 &&
        <div className={classes.savingsContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: '#cce8fe', top: 30, marginLeft: '5%', alignItems: 'center', 
              flexDirection: 'column', width: '90%', height: 380, borderRadius: 8 }}
            >
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 18, height: 12, marginTop: 15}} >You may want to order now:</p>
              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 28, height: 32}} >{selectedOption.details[0].generic.name} {selectedOption.details[0].dosage.name}</p>
              <div style={{display: "flex", alignItems: 'flex-start', flexDirection:'row',width:'100%', borderRadius: 3, 
                backgroundColor: 'transparent', height: 40, marginTop: 5, zIndex: 100}}>
                {
                    tabArr.map((item,index)=> {
                      return <div key={index} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '33%'}} onClick={() => tabAction(item.tabId)} >
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 3, justifyContent: 'center', alignItems: 'center', width: 24, height: 24, borderWidth: 2, border: '3px solid #000000', borderRadius: 3, marginRight: 5}}>
                          {item.isSelect && <img src={CheckBoxCheck} style={{width: 20, height: 20}}/>}
                        </div>
                        <p style={{color: '#000000', fontSize: 20}} >{item.tabName}</p>
                      </div>
                    })
                }
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30, marginBottom: 5}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 24, width: '40%', textAlign: 'left'}} >Option: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 24, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>{selectedOption.name}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Total: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>₱{formatNumberWithComma(selectedOption.details[0].brand.price * quantity, 2)}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Savings: </p>
                <p style={{height: 18, fontWeight: '500', fontSize: 20, width: '40%', marginLeft: 5, color: '#00599d', textAlign: 'left'}}>₱{formatNumberWithComma((selectedData.brand.price - selectedOption.details[0].brand.price) * quantity, 2)}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8, height: 30, marginTop: 0}}>
                <p style={{color: 'black', fontWeight: '500', fontSize: 20, width: '40%', textAlign: 'left'}} >Quantity: </p>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '40%', marginTop: 10}}>
                  <div style ={{cursor: 'pointer', width: 30, height: 30, zIndex: 1}} onClick={(e) => addQty(e)}>
                    <img src={Plus} style={{widht: 20, height: 20, marginRight: 10}}/>
                  </div>
                  {/* <p style={{height: 18, fontSize: 20, height: 20}}>{quantity} {quantity == 1 ? 'tab' : 'tabs'}</p> */}
                  <p style={{height: 18, fontSize: 20, height: 22}}>{quantity}</p>
                  <div style ={{cursor: 'pointer', width: 30, height: 30, zIndex: 1}} onClick={(e) => minusQty(e)}>
                    <img src={Minus} style={{widht: 20, height: 20, marginLeft: 10}} />
                  </div>
                </div>
              </div>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <p style={{fontSize: 20, fontWeight: '500', color: 'red', textAlign: 'center', height: 12, paddingTop: 20}}>You save</p>
                <p style={{fontSize: 60, fontWeight: '700', color: 'red', textAlign: 'center', height: 30}}>{formatNumberWithComma((selectedData.brand.price - selectedOption.details[0].brand.price)/selectedData.brand.price * 100, 0)}%</p>
              </div>
            </div>
            </>
          </div>
          <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            { quantity > 0 &&
              <div onClick={() => onOrderPress()} style={{cursor: 'pointer'}} >
                <MyButton title="Add to Cart"/>
              </div>
            }
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader goBackClick={() => setLevel(1)} title="" style={{position: 'absolute'}}
              // hasCartBtn
              // onCartPress={() => navigate(routes.cart, { state: {userData: props.userData} })}
              hasBackBtn
            />
          </div>
        </div>
      }
      <Popup show={showCancelPopup} 
        title={'Are you sure you want to cancel?'}
        onCancelClick={() => setShowCancelPopup(false)}
        yesText='Yes'
        onYesClick={() => {
          setShowCancelPopup(false)
          navigate(routes.home);
        }}
        noText='No'
        onNoClick={() => {
          setShowCancelPopup(false)
        }}
      />
    </div>
  );
};


export default Savings;